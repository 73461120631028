.all-series-content {
    @include calc-font(16, 24);

    &-anchor {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 50px;

        @include media-sp {
            margin-top: calcP(40);
            flex-wrap: wrap;
        }

        li {
            margin-left: 10px;

            &:first-child {
                margin-left: 0;
            }

            @include media-sp {
                &:nth-child(17) {
                    margin-left: 0;
                }
            }
        }

        a {
            color: #264064;
            @include calc-font(18, 21);

            font-weight: var(--font-weight-regular);

            @include media-sp {
                margin-top: calcP(40);
                @include calc-fontvw(18, 36);
            }
        }
    }

    .all-series-content-parts {
        margin-top: 40px;

        &-wrap {
            @include media-sp {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;
            }
        }

        @include media-sp {
            margin-top: calcP(40);
            width: 48%;
        }

        .all-series-content-parts__ttl {
            color: #000;
            border-bottom: 2px solid #e8e8e8;
            padding-bottom: 10px;
            @include calc-font(24, 28);

            font-weight: var(--font-weight-bold);

            @include media-sp {
                @include calc-fontvw(22, 26.4);
            }
        }

        .all-series-content-list {
            @include media-pc {
                display: grid;
                -ms-grid-columns: 1fr 1fr 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                columns: 4;
            }

            margin-top: 40px;

            @include media-sp {
                display: block;
                margin-top: 30px;
            }

            li {
                color: #666;
                margin-right: 40px;
                margin-bottom: 20px;
                @include calc-font(16, 19);

                font-weight: var(--font-weight-regular);

                a {
                    color: #666;
                    @include calc-font(16, 19);

                    font-weight: var(--font-weight-regular);
                    transition: 0.3s;

                    &:hover {
                        color: #000;
                    }
                }

                @include media-sp {
                    @include calc-fontvw(14, 16.8);

                    a {
                        @include calc-fontvw(14, 16.8);
                    }
                }

                &:nth-child(4n) {
                    margin-right: 0;
                }

                @include media-sp {
                    width: 100%;
                    margin-right: 0;
                }

                &.all-series-content-list-parts__pic-anchor {
                    a {
                        color: #000;
                        background: #f1f1f1;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 5px;

                        &:hover {
                            text-decoration: none;
                            opacity: 0.7;
                        }

                        img {
                            object-fit: cover;
                            height: 50px;
                            width: 50px;
                        }

                        > p {
                            padding-left: 15px;

                            @include media-sp {
                                padding-left: 7px;
                            }
                        }
                    }
                }
            }
        }
    }
}
