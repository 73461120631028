.cart__product-list--myorders {
    .cart__product-list-article {
        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
    }
}

.myaccount-order-detail {
    &__sttl {
        margin-bottom: 10px;
        color: #000;
        font-weight: 700;

        @include media-sp {
            margin-bottom: calcP(5);
        }
    }

    &__ttl {
        display: flex;
        align-items: flex-start;
        line-height: 1;

        @include media-sp {
            @include calc-fontvw(28, 28);
        }
    }

    &__label-text {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;

        @include media-sp {
            flex-direction: column;
            align-items: flex-start;
        }

        .myaccount-order-detail__text {
            margin-left: 10px;
            @include calc-font(16, 19.2);
            color: #264064;

            &__shipped {
                color: #748B9F;
            }

            &__error ,
            &__hold {
                color: #FF006C;
            }

            &__canceled {
                color: #ACACAC;
            }

            @include media-sp {
                margin-left: 0;
                margin-top: 10px;
                @include calc-fontvw(16, 21);
            }
        }
    }

    &__label {
        display: inline-block;

        @include media-pc {
            width: auto;
            height: 24px;
        }

        @include media-sp {
            width: auto;
            height: 18px;
        }
    }

    &__date {
        margin-top: 20px;

        @include media-sp {
            margin-top: calcP(20);
        }
    }

    &__button {
        position: absolute;
        top: 0;
        right: 0;

        @include media-sp {
            position: relative;
            margin-top: calcP(20);
        }

        button {
            img {
                vertical-align: top;
            }

            .txt {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
    &__tracking-notice {
        margin-top: 20px;
        line-height: 1.375;
    }
}

.myaccount-order-detail__tracking-number-list {
    padding-top: 25px;

    @include media-sp {
        padding-top: calcP(15);
    }

    li {
        display: flex;
        padding: 15px 0;
        border-bottom: 1px solid #e8e8e8;
        align-items: center;

        @include media-sp {
            padding: calcP(15) 0;
        }

        span {
            display: inline-block;
        }

        .num {
            flex-basis: 236px;

            @include media-sp {
                flex-basis: 57.3134%;
            }
        }

        .company {
            flex: 1;
        }
    }
}

.cart__ttl--myaccount-order-detail-support {
    margin-top: 80px;

    @include media-sp {
        margin-top: calcP(70);
    }
}

.myaccount-order-detail-payment-option-table {
    margin-bottom: 0;

    th,
    td {
        vertical-align: middle;

        img {
            margin-right: 5px;
            display: inline-block;
            vertical-align: middle;
        }

        &.is-padding-left {
            padding-left: 36px;
        }
    }
}

.myaccount-order-detail__notice {
    margin-top: 20px;
    @include calc-font(14, 21);

    @include media-sp {
        margin-top: calcP(20);
        @include calc-fontvw(14, 21);
    }
    .font-red {
        color: #f00;
    }
}

.myaccount-payment-failed {
    &-wrapper {
        position: fixed;
        display: flex;
        justify-content: center;
        padding: 30px 0;
        left: 0;
        bottom: 0;
        width: 100vw;
        background-color: #f1f1f1;
        box-shadow: 0 -5px 6px rgba(0, 0, 0, 0.16);
        z-index: 10;

        @include media-sp {
            padding: 30px calcP(20);
            box-sizing: border-box;
        }
    }

    &-content {
        display: flex;
        width: 540px;
        align-items: center;

        @include media-sp {
            display: block;
            width: 100%;
        }

        &-txt {
            flex-basis: 400px;

            &__ttl {
                @include calc-font(18, 18);

                font-weight: 700;
                color: #000;

                @include media-sp {
                    @include calc-font(16, 16);
                }
            }

            &__description {
                margin-top: 10px;
                @include calc-font(16, 24);

                @include media-sp {
                    margin-top: calcP(10);
                    @include calc-fontvw(14, 21);
                }

                a {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        &-button {
            button {
                min-width: 120px;

                @include media-sp {
                    margin-top: calcP(20);
                    width: 100%;
                }
            }
        }
    }
}

.modal-finalpayment-wrapper {
    @include media-pc {
        padding-right: 40px;
        padding-left: 40px;
        width: 600px;
    }
}

.modal-finalpayment-price-wrapper {
    margin-top: 40px;
    padding: 0 40px;
    border-top: 1px solid #e8e8e8;

    @include media-sp {
        margin-top: calcP(30);
        padding: 0 calcP(10);
    }
}

.checkout-price-detail__list--finalpayment {
    margin-top: 0;
    padding: 20px 0;

    @include media-sp {
        padding: calcP(20) 0;
    }

    &.first {
        border-bottom: 1px solid #e8e8e8;
    }

    li {
        span.term {
            flex-basis: 253px;

            @include media-sp {
                flex-basis: 51.42857142857143%;
            }
        }

        span.price {
            flex-basis: 186px;

            @include media-sp {
                flex-basis: 48.57142857142857%;
            }
        }
    }
}

.modal-finalpayment-address {
    &-wrapper {
        margin-top: 19px;
        margin-bottom: 40px;
        padding: 20px 40px;
        text-align: left;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;

        @include media-sp {
            margin-top: calcP(50);
            margin-bottom: calcP(20);
            padding: calcP(20) 0;
        }
    }

    &__ttl {
        margin-bottom: 20px;
        font-weight: 700;
        color: #000;

        @include media-sp {
            margin-bottom: calcP(20);
            @include calc-fontvw(16, 16);
        }
    }

    &__detail {
        @include calc-font(16, 24);

        @include media-sp {
            @include calc-fontvw(16, 24);
        }

        .name {
            font-weight: 500;
        }
    }
}

.modal-finalpayment-buttons {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;

    @include media-sp {
        padding: 0;
        display: block;

        li {
            margin-top: 20px;

            &:first-child {
                margin-top: 0;
            }
        }

        button {
            width: 100%;
        }
    }
}

.myaccount-payment-failed-wrapper--payment-confirmation {
    .myaccount-payment-failed-content {
        justify-content: center;

        button {
            min-width: 250px;

            @include media-sp {
                margin-top: 0;
            }
        }
    }
}

.myaccount-order-edit-bar {
    margin-top: 50px;
    padding: 14px 0;
    color: #fff;
    font-weight: 500;
    text-align: center;
    background-color: #264064;

    @include media-sp {
        margin: calcP(40) calc(50% - 50vw) 0;
        @include calc-fontvw(16, 16);
    }
}

.myaccount-order-edit-info {
    text-align: right;
    margin-top: 10px;
    line-height: 1.375;
    @include calc-font(14, 21);

    @include media-sp {
        margin-top: calcP(10);
        @include calc-fontvw(14, 21);
    }
    .font-red {
        color: #f00;
    }
}
