.cmn-footer {
    // パラメーター設定
    $cmn-footer_pc_all_min-height: 414px;//全体の高さ
    $cmn-footer_pc_pattern_height: 164px;//帯の高さ
    $cmn-footer_pc_pattern_not-over_height: 81px;//帯が重なっていない高さ

    $cmn-footer_sp_all_min-height: 276px;//全体の高さ
    $cmn-footer_sp_pattern_height: 110px;//帯の高さ
    $cmn-footer_sp_pattern_not-over_height: 46px;//帯が重なっていない高さ

    // スタイル設定
    margin-top: auto;
    position: relative;
    box-sizing: border-box;
    padding-top: $cmn-footer_pc_pattern_not-over_height;
    min-height: $cmn-footer_pc_all_min-height;

    @include media-sp {
        padding-top: $cmn-footer_sp_pattern_not-over_height;
        min-height: $cmn-footer_sp_all_min-height;
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: $cmn-footer_pc_pattern_height;
        background: url(../images/common/footer_pattern.svg) repeat-x;
        background-position: top center;
        background-size: auto 100%;

        @include media-sp {
            height: $cmn-footer_sp_pattern_height;
        }
    }

    &__inner {
        position: relative;
        min-height: ($cmn-footer_pc_all_min-height - $cmn-footer_pc_pattern_not-over_height);
        background-color: #ddd;
        padding-top: ($cmn-footer_pc_pattern_height - $cmn-footer_pc_pattern_not-over_height);
        padding-right: 20px;
        padding-bottom: 54px;
        padding-left: 20px;
        box-sizing: border-box;

        @include media-sp {
            min-height: ($cmn-footer_sp_all_min-height - $cmn-footer_sp_pattern_not-over_height);
            padding-top: ($cmn-footer_sp_pattern_height - $cmn-footer_sp_pattern_not-over_height);
        }
    }

    &__copyright {
        position: absolute;
        left: 0;
        bottom: 13px;
        display: block;
        width: 100%;
        @include calc-font(14, 30);

        text-align: center;

        @include media-sp {
            bottom: 12px;
            @include calc-font(12, 30);
        }
    }

    // ------ Normal ------
    @function calc_footer_width_percent($value) {
        @return calc(100% * $value / 1240);
    }

    &__blocks {
        display: flex;
        flex-direction: row;

        @include media-pc {
            justify-content: center;
            max-width: 1240px;
            margin-right: auto;
            margin-left: auto;
            padding-bottom: 20px;
        }

        @include media-sp {
            flex-direction: column;
        }
    }

    &__block-item {
        @include media-pc {
            max-width: 210px;
            flex-basis: calc_footer_width_percent(210);
            margin-left: calc_footer_width_percent(40);
        }

        @include media-sp {
            padding-right: 20px;
            padding-left: 20px;
        }

        &:nth-child(1) {
            @include media-pc {
                order: 3;
                max-width: 240px;
                flex-basis: calc_footer_width_percent(240);
            }
        }

        &:nth-child(2) {
            @include media-pc {
                order: 1;
                margin-left: 0;
            }
        }

        &:nth-child(3) {
            @include media-pc {
                order: 2;
            }
        }

        &:nth-child(4) {
            @include media-pc {
                order: 4;
            }
        }

        &:nth-child(5) {
            @include media-pc {
                order: 5;
            }
        }
    }

    &__logo {
        padding-top: 20px;
        text-align: center;

        img {
            width: 240px;
            @include media-sp {
                width: 180px;
            }
        }
    }

    &__block-item + &__block-item {
        margin-top: 20px;
    }

    &__label {
        border-bottom: solid 2px #ccc;
        @include calc-font(16, 36);

        font-weight: var(--font-weight-medium);

        @include media-pc {
            margin-top: -6px;
        }
    }

    &__link-list {
        margin-top: 20px;

        li {
            @include calc-font(16, 20);
        }

        li + li {
            margin-top: 12px;
        }

        a {
            &:link,
            &:visited {
                color: #666;
                transition: color 0.3s ease-out;
            }

            &:hover,
            &:active {
                color: #000;
                text-decoration: underline;
            }
        }
    }

    &__link-list + &__label,
    &__input + &__label,
    &__input-error-text + &__label,
    &__input-success-text + &__label,
    form.sendgrid + &__label {
        margin-top: 20px;
    }

    &__social-list {
        ul {
            display: flex;
            padding-top: 20px;
        }

        li + li {
            margin-left: 18px;
        }

        svg {
            width: 40px;
            height: 40px;
        }
    }

    &__social-list a:link &__svg-base-color,
    &__social-list a:visited &__svg-base-color {
        fill: #666;
        transition: all 0.3s ease-out;
    }

    &__social-list a:hover &__svg-base-color,
    &__social-list a:active &__svg-base-color {
        fill: #000;
    }

    &__payment {
        padding-top: 20px;

        img {
            width: 182px;
            aspect-ratio: 182 / 213;
        }
    }

    &__input-error-text {
        display: none;
        color: #ff006c;
        @include calc-font(14, 16.8);

        margin-top: 8px;
    }

    &__input-success-text {
        display: none;
        color: #000;
        @include calc-font(14, 16.8);

        margin-top: 8px;
    }

    &__input {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        margin-top: 20px;
        height: 44px;
        width: 100%;

        @include media-sp {
            margin-top: calcP(20);
        }

        .input__text {
            border: none;
            width: calc(100% - 24px);
        }

        .button-footer-email-submit {
            background: #666;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 24px;

            &:hover {
                background: #000;
                transition: 0.3s;
            }

            img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(18deg) brightness(105%) contrast(104%);
            }
        }

        &-error {
            + .cmn-footer__input-error-text {
                display: block;
            }
        }

        &-success {
            + .cmn-footer__input-success-text {
                display: block;
            }
        }
    }
}
