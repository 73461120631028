.faq-row-wrapper {
    display: flex;

    @include media-sp {
        flex-direction: column;

        .myaccount-row-nav {
            .myaccount__nav-list {
                margin-top: calcP(40);
            }
        }
    }
}

.faq-row-nav {
    width: 304px;
    order: 1;

    @include media-sp {
        width: 100%;
    }
}

.faq-row-content {
    position: relative;
    margin-left: 65px;
    flex: 1;
    order: 2;

    @include media-sp {
        margin-left: 0;
        margin-top: calcP(40);
    }

    .faq-parts-ttl {
        margin-top: 50px;
        border-bottom: 2px solid #E8E8E8;
        color: #000000;
        font-weight: var(--font-weight-bold);
        @include calc-font(24, 28);
        padding-bottom: 10px;
        margin-top: 50px;
    
        @include media-sp {
            margin-top: calcP(70);
            @include calc-fontvw(22, 26.4);
        }

        &__first {    
            @include media-sp {
                margin-top: calcP(30);
            }
        }
    }

    .faq-parts-qa {
        border-bottom: 1px solid #E8E8E8;
        padding-bottom: 20px;

        &-wrap {
            margin-top: 40px;
    
            @include media-sp {
                margin-top: calcP(30);
            }
        }

        &__a ,
        &__q {
            color: #666666;
            display: table;
            @include calc-font(16, 24);
            font-weight: var(--font-weight-regular);
            margin-top: 20px;
    
            @include media-sp {
                margin-top: calcP(20);
                @include calc-fontvw(16, 24);
            }

            > p {
                &:first-child {
                    width: 20px;
                }
            }

            > div {
                a {
                    text-decoration: underline;
                }
                > p {
                    margin-top: 10px;
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }

            .dot-list {
                margin-top: 10px;
                &:first-child {
                    margin-top: 0;
                }
                li {
                    display: table;

                    > p {
                        display: table-cell;

                        &:first-child {
                            @include calc-font(6, 6);
                            width: 15px;
                        }
                    }
                }
            }
            
            .num-list {
                margin-top: 10px;
                &:first-child {
                    margin-top: 0;
                }

                li {
                    display: table;

                    > p {
                        display: table-cell;

                        &:first-child {
                            width: 15px;
                        }
                    }
                }
            }

            > p ,
            > div {
                display: table-cell;
            }
        }

        &__q {
            transition: .3s;
            text-align: left;
            
            @include media-pc {
                &:hover {
                    cursor: pointer;
                    color: #000000;
                    text-decoration: underline;
                }
            }
        }

        &__a {
            display: none;
        }
    }
}
