@use "sass:math";

.cmn-content-main-content-checkout-inner {
    position: relative;
}

.order-status-list {
    &-wrapper {
        position: absolute;
        width: 100%;
        display: flex;
        top: 0;
        left: 0;
        justify-content: center;

        @include media-sp {
            position: relative;
            margin-top: calcP(30);
        }
    }

    display: flex;
    width: 400px;
    justify-content: space-between;

    @include media-sp {
        padding: 0 4%;
        width: 100%;
        box-sizing: border-box;
    }

    &__article {
        $this: &;

        @include media-sp {
            position: relative;
            width: math.div(100, 3) + 0%;
            text-align: center;
        }

        &-number {
            position: relative;
            margin: 0 auto 10px;
            font-weight: 700;
            color: #fff;
            width: 35px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: url(../images/order/progress_hex_gray.svg) no-repeat left top;

            @include media-sp {
                position: relative;
                display: flex;
                align-items: center;
                margin-bottom: calcP(10);
                @include calc-fontvw(16, 16);

                width: math.div(35, 105) * 100 + 0%;
                height: auto;
                padding-top: 40%;
                line-height: 0;
                background-size: contain;

                .num {
                    display: flex;
                    width: 100%;
                    top: 35%;
                    left: 0;
                    position: absolute;
                    justify-content: center;
                    align-items: center;
                }
            }

            &::before {
                position: absolute;
                content: "";
                top: 50%;
                left: 35px;
                width: 140px;
                height: 1px;
                background-color: #ccc;

                @include media-sp {
                    width: 300%;
                    top: 34%;
                    left: 0;
                    z-index: -1;
                }
            }

            &.active {
                &::before {
                    background-color: #000;
                }
            }
        }

        &-txt {
            @include calc-font(12, 12);
            @include media-sp {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                @include calc-fontvw(10, 10);
            }
        }

        &:last-child {
            #{$this}-number {
                &::before {
                    display: none;
                }
            }
        }

        &.active {
            #{$this}-number {
                background-image: url(../images/order/progress_hex_blue.svg);
            }
        }
    }
}

.checkout-content {
    display: flex;
    justify-content: space-between;

    @include media-sp {
        display: block;
    }

    &-inner {
        width: 46.20967741935484%;

        @include media-sp {
            width: 100%;
        }
    }

    &-main {
        margin-top: 40px;
        margin-bottom: 80px;

        @include media-sp {
            margin-top: calcP(30);
            margin-bottom: calcP(70);
        }

        &.no-margin {
            margin-bottom: 0;
        }

        &.product-options {
            .shipping-method-radios {
                margin-top: 20px;
                flex-wrap: wrap;
                label {
                    width: calc(47% - 0.7em);
                    margin-bottom: 20px;
                }
                @include media-sp {
                    margin-top: 20px;
                    width: 100%;
                    justify-content: left;
                    label {
                        margin-bottom: 2px;
                        margin-right: 2px;
                        width: 48%;
                    }
                }

            }
            margin-bottom: 30px;
        }
    }

    &-address {
        margin-bottom: 20px;
        display: flex;

        @include media-sp {
            margin-bottom: calcP(20);
            justify-content: space-between;
            align-items: flex-start;
        }

        &__txt {
            margin-right: 40px;
            max-width: 340px;
            @include calc-font(16, 24);

            @include media-sp {
                margin-right: 0;
                max-width: 73.96825396825397%;
                @include calc-fontvw(16, 24);
            }

            .name {
                font-weight: 500;
            }

            &--medium {
                font-weight: 500;
            }

            &--point {
                margin-top: 17px;

                @include media-sp {
                    margin-top: calcP(17);
                }
            }
        }
    }
}

.shipping-method-radios {
    display: flex;

    &--payment {
        margin-top: 20px;

        @include media-sp {
            margin-top: calcP(20);
        }
    }

    input[type="radio"] {
        display: none;

        &:checked + label {
            border: 2px solid #264064;
        }
    }

    label {
        display: inline-block;
        margin-right: 20px;
        padding: 15px 0;
        width: 47%;
        text-align: center;
        font-weight: 500;
        color: #000;
        border: 2px solid #e8e8e8;
        border-radius: 6px;
        cursor: pointer;

        @include media-pc {
            transition: 0.3s;
        }

        @include media-sp {
            margin-right: math.div(20, 315) * 100+0%;
            width: math.div(157, 315) * 100+0%;
            @include calc-fontvw(14, 21);
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.shipping-method-notice {
    display: none;
    margin-top: 30px;
    @include calc-font(14, 21);

    @include media-sp {
        @include calc-fontvw(12, 18);
    }

    &.active {
        display: block;
    }

    a {
        text-decoration:underline;
    }

    a:hover {
        fill: #000;
    }
}

.checkout-payment {
    &__ttl {
        font-weight: 700;
        @include calc-font(18, 18);

        color: #000;
        @include media-sp {
            @include calc-fontvw(18, 18);
        }
    }

    &__description {
        margin: 17px 0 10px;
        @include calc-font(14, 14);

        @include media-sp {
            margin: calcP(17), 0 calcP(10);
            @include calc-fontvw(14, 14);
        }
    }
}

.checkout-payment-point-form {
    @include media-sp {
        display: flex;
    }

    .input__text {
        margin-right: 10px;
        width: 260px;

        @include media-sp {
            padding-top: 12px;
            padding-bottom: 12px;
            margin-right: math.div(10, 335) * 100+0%;
            width: math.div(245, 335) * 100+0%;
            height: auto;
        }
    }

    .button-change {
        width: 100px;
        height: 44px;

        @include media-sp {
            padding: 12px 0;
            width: math.div(80, 335) * 100+0%;
            @include calc-fontvw(14, 14);

            height: auto;
        }
    }
}

.checkout-payment__coupon-ttl-wrapper {
    display: flex;
    margin-top: 40px;
    max-width: 370px;
    align-items: center;
    justify-content: space-between;

    @include media-sp {
        margin-top: calcP(30);
        max-width: 100%;
    }
}

.checkout-payment__coupon-reset-button {
    @include calc-font(14, 14);

    @include media-sp {
        @include calc-fontvw(14, 14);
    }
}

.checkout-payment__ttl--coupon {
    margin-top: 40px;
    @include media-sp {
        margin-top: calcP(30);
    }
}

.checkout-payment-coupon-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 370px;
    height: 122px;
    border: 1px solid #e8e8e8;

    @include media-sp {
        margin-top: calcP(20);
        padding: 33px 0;
        width: 100%;
        height: auto;
    }

    .button-change {
        width: 150px;
        height: 44px;

        @include media-sp {
            padding: 13px 30px;
            width: auto;
            height: auto;
        }
    }
}

.checkout-content-coupon__image {
    margin-top: 20px;
    max-width: 370px;

    @include media-sp {
        margin-top: calcP(20);
        max-width: initial;

        img {
            width: 100%;
        }
    }
}

.jsCheckoutBillingAddress {
    display: none;

    &.active {
        display: block;
    }
}

.jsCouponWrapper {
    display: none;

    &.active {
        display: flex;
    }
}

.jsCheckoutCoupon {
    display: none;

    &.active {
        display: block;
    }
}

.jsCouponResetButton {
    display: none;

    &.active {
        display: flex;
    }
}

.checkout-payment-method, .checkout-payment-account {
    margin-top: 17px;

    &--image {
        margin-top: 30px;

        @include media-sp {
            margin-top: calcP(30);
        }
    }

    @include media-sp {
        margin-top: calcP(17);
    }
}

.checkout-product-detail {
    &-list {
        margin-top: -40px;
        border-bottom: 1px solid #e8e8e8;

        @include media-pc {
            padding: 20px 0;
        }

        @include media-sp {
            margin-top: calcP(-30);
        }
    }

    padding: 20px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;

    &:last-child {
        border-bottom: none;
    }

    @include media-sp {
        padding: calcP(30) 0;
        align-items: flex-start;
    }

    &__img {
        margin-right: 20px;

        @include media-pc {
            max-width: 100px;
        }

        @include media-sp {
            margin-right: math.div(20, 315) * 100+0%;
            width: math.div(100, 315) * 100+0%;

            img {
                width: 100%;
            }
        }
    }

    &-txt {
        &__work,
        &__ttl {
            color: #000;
        }

        &__work {
            @include calc-font(14, 22);

            font-weight: 500;

            @include media-sp {
                @include calc-fontvw(14, 22);
            }
        }

        &__ttl {
            @include calc-font(16, 19.2);

            font-weight: 500;

            @include media-sp {
                @include calc-fontvw(16, 19.2);
            }
        }

        &__code {
            margin: 7px 0 10px;
            @include calc-font(14, 14);

            @include media-sp {
                @include calc-fontvw(14, 14);
            }
        }

        &__qty {
            color: #000;

            &-wrapper {
                display: flex;
                @include calc-font(14, 14);

                align-items: flex-start;
                color: #000;

                @include media-sp {
                    @include calc-fontvw(14, 14);

                    &.sale {
                        display: block;

                        .checkout-product-detail-txt__qty {
                            @include media-sp {
                                margin-bottom: calcP(10);
                            }
                        }

                        .checkout-product-detail-txt__qty-price {
                            margin-left: 0;

                            .price {
                                margin-left: 0;
                            }
                        }
                        &:not(:has(.price-before)) {
                            .checkout-product-detail-txt__qty {
                                display: inline-block;
                                vertical-align: top;
                            }
                            .checkout-product-detail-txt__qty-price {
                                display: inline-block;
                                margin-left: 20px;
                            }
                            .promotion-message {
                                display: block;
                                margin-top: 0;
                            }
                        }
                    }
                }

                .price-before {
                    display: inline-block;
                    margin-left: 20px;
                    margin-right: -10px;
                    @include calc-font(12, 12);

                    color: #ccc;

                    @include media-sp {
                        margin-left: 0;
                        margin-right: 10px;
                        @include calc-fontvw(12, 12);
                    }
                }

                .price {
                    margin-left: 20px;
                    display: inline-block;
                }

                .price-off {
                    display: inline-block;
                    margin-left: 10px;
                    color: #ff006c;
                }

                .price-off-message {
                    display: inline-block;
                    margin-left: 20px;
                    padding-top: 5px;
                    @include calc-font(12, 12);

                    color: #666;

                    @include media-sp {
                        display: block;
                        margin-left: 0;
                        @include calc-fontvw(10, 10);
                    }
                }

                .promotion-message {
                    display: inline-block;
                    margin-left: 20px;
                    font-size: 0.75rem;
                    line-height: 1;
                    @include media-sp {
                        margin: calcP(10) 0 0 0;
                    }
                }
            }
        }
    }
}

.checkout-price-detail {
    &-normal {
        display: none;

        &.active {
            display: block;
        }
    }

    &-edit {
        display: none;

        &.active {
            display: block;
        }
    }

    &-apply {
        display: none;

        &.active {
            display: block;
        }
    }
}

.checkout-price-detail__list {
    margin-top: 20px;
    padding: 0 0 20px;

    @include media-sp {
        margin-top: calcP(20);
        padding: 0 0 calcP(20);
    }

    li {
        margin-top: 20px;
        display: flex;
        @include calc-font(16, 16);

        @include media-sp {
            margin-top: calcP(20);
            @include calc-fontvw(16, 16);
        }

        span.term {
            display: flex;
            flex-basis: 310px;
            justify-content: flex-end;
            align-items: flex-start;

            @include media-sp {
                flex-basis: math.div(190, 315) * 100+0%;
            }
        }

        span.price {
            display: block;
            flex-basis: 265px;
            text-align: right;

            @include media-sp {
                flex-basis: math.div(146, 315) * 100+0%;
            }
        }

        span.point {
            display: inline-block;
            padding-top: 5px;
            @include calc-font(14, 14);

            @include media-sp {
                padding-top: calcP(5);
                @include calc-fontvw(14, 14);
            }
        }

        &:first-child {
            margin-top: 0;
        }
    }

    &--total {
        margin-top: 0;
        padding: 27px 0;
        border-top: 1px solid #e8e8e8;

        @include media-sp {
            padding: calcP(27) 0;
        }

        li {
            margin-top: 20px;
            color: #000;

            &:first-child {
                margin-top: 0;
            }

            span.term {
                font-weight: 500;
                align-items: center;

                @include media-sp {
                    @include calc-fontvw(16, 16e);
                }

                &__normal {
                    color: #666;
                    font-weight: normal;

                    @include media-sp {
                        @include calc-fontvw(16, 16);
                    }
                }
            }

            span.price {
                @include calc-font(28, 28);

                font-weight: 600;

                @include media-sp {
                    @include calc-fontvw(24, 24);
                }

                &__normal {
                    color: #666;
                    font-weight: normal;
                    @include calc-font(16, 16);

                    @include media-sp {
                        @include calc-fontvw(16, 16);
                    }
                }
            }
        }
    }
}

.checkout-payment__coupon-wrapper {
    display: flex;
    align-items: center;
}

.checkout-price__point-notice {
    @include calc-font(14, 19);

    text-align: right;

    &__last {
        margin-top: 10px;
        @include media-sp {
            margin-top: calcP(10);
        }
    }

    @include media-sp {
        @include calc-fontvw(14, 19);
    }
}

.button-choose-option {
    border-radius: 0;
    width: 100%;
    height: 55px;
    background-image: url(../images/common/icon_accordion_plus.svg);
    background-repeat: no-repeat;
    background-position: 15px center;

    @include media-sp {
        height: auto;
        padding: 20px 0;
        line-height: 1;
    }
}

.button-confirm-order {
    margin-top: 20px;
    border-radius: 6px;
    width: 100%;
    height: 55px;

    &--buy-now {
        margin-top: 40px;

        @include media-sp {
            margin-top: calcP(40);
        }
    }

    @include media-sp {
        margin-top: calcP(20);
        padding: 20px 0;
        height: auto;
    }
}

.button-complete-order {
    margin-top: 20px;
    border-radius: 6px;
    width: 100%;
    height: 55px;

    &--buy-now {
        margin-top: 40px;

        @include media-sp {
            margin-top: calcP(40);
        }
    }

    @include media-sp {
        margin-top: calcP(20);
        padding: 20px 0;
        height: auto;
    }
}

.button-back-cart {
    border-radius: 6px;
    display: block;
    margin: 100px auto 0;
    width: 372px;
    height: 55px;

    @include media-sp {
        margin-top: calcP(60);
        padding: 20px 0;
        width: 100%;
        height: auto;
    }
}

.checkout-payment-option {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
}

.modal-important-notice {
    &-wrapper {
        padding: 35px 40px 50px;
        max-width: 777px;
        box-sizing: border-box;

        @include media-sp {
            padding: calcP(40) calcP(20) calcP(60);
            max-width: initial;
        }
    }

    &__ttl {
        @include calc-font(35, 35);

        font-weight: 900;
        color: #000;

        @include media-sp {
            @include calc-fontvw(28, 28);
        }
    }
}

.modal-important-notice-txt {
    &-wrapper {
        margin-top: 30px;
        padding: 20px 15px;
        height: 299px;
        overflow: auto;
        box-sizing: border-box;
        border: 1px solid #ccc;

        @include media-sp {
            margin-top: calcP(30);
            padding: calcP(20) calcP(15);
            height: 325px;
        }

        .custom-checkbox {
            margin-top: 25px;
            text-align: center;

            p.checkbox-message {
                margin-bottom: 25px;
            }
        }
    }

    &__body {
        pre {
            white-space: pre-wrap;
            @include calc-font(16, 24);

            @include media-sp {
                @include calc-fontvw(16, 24);
            }

            .ttl {
                @include calc-font(20, 24);

                font-weight: 500;
                color: #000;

                @include media-sp {
                    @include calc-fontvw(18, 24);
                }
            }

            .sttl {
                font-weight: 500;
                color: #000;
            }
        }

        .content-asset {
            ul {
                list-style: disc inside;
            }

            .supplement {
                color: #B0B0B0;
                font-size: 95%;
            }
        }
    }
}

.modal-important-notice-buttons {
    margin-top: 40px;
    display: flex;
    justify-content: center;

    @include media-sp {
        display: block;
        margin-top: calcP(30);
    }

    li {
        margin-right: 20px;

        @include media-sp {
            margin-right: 0;
        }

        &:last-child {
            margin-right: 0;

            @include media-sp {
                margin-top: calcP(20);
            }
        }
    }

    .button-gray {
        border-radius: 6px;

        @include media-sp {
            width: 100%;
        }
    }

    .button-continue {
        @include media-sp {
            width: 100%;
        }
    }
}

.checkout-complete {
    &-wrapper {
        margin-top: 100px;
        text-align: center;

        @include media-sp {
            margin-top: calcP(40);
        }

        .button-basic {
            margin-top: 80px;

            @include media-sp {
                margin-top: calcP(70);
                width: 100%;
            }
        }
    }

    &__ttl {
        @include calc-font(36, 36);

        font-weight: 700;
        color: #000;

        @include media-sp {
            @include calc-fontvw(32, 40);
        }
    }

    &__sttl {
        margin-top: 20px;
        @include calc-font(24, 24);

        font-weight: 500;
        color: #000;

        @include media-sp {
            margin-top: calcP(10);
            @include calc-fontvw(18, 24);
        }
    }

    &__description {
        margin-top: 40px;
        @include calc-font(16, 24);

        @include media-sp {
            margin-top: calcP(20);
            @include calc-fontvw(16, 24);
        }
    }
}

.modal-coupon {
    &-wrapper {
        padding: 36px 40px 50px;
        width: 862px;
        text-align: center;

        @include media-sp {
            width: 100%;
            padding: calcP(40) calcP(20);
            box-sizing: border-box;
        }
    }

    &__ttl {
        @include calc-font(32, 32);

        font-weight: 900;
        color: #000;

        @include media-sp {
            @include calc-fontvw(28, 28);
        }
    }

    &__list {
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        @include media-sp {
            padding-top: calcP(15);
        }

        li {
            margin-top: 20px;
            width: 410px;

            @include media-pc {
                &:nth-child(-n + 2) {
                    margin-bottom: 20px;
                }
            }

            @include media-sp {
                margin-top: calcP(33);
            }

            p {
                text-align: left;
            }
        }

        &__ttl {
            @include calc-font(18, 18);

            font-weight: 900;
            color: #000;
            margin: 20px 0 10px;
        }

        &__text {
            @include calc-font(16, 24);

            color: #666;
        }
    }
}

.checkout-choose-payment-option-outer {
    margin-top: 40px;
    background-color: #f1f1f1;

    @include media-sp {
        margin-top: calcP(40);
    }
}

.checkout-choose-payment-option {
    &-wrapper {
        display: none;
        padding: 30px 20px 40px;
        background-color: #f1f1f1;

        @include media-sp {
            padding: calcP(30) calcP(10) calcP(40);
        }

        &.active {
            display: block;
        }

        .custom-select {
            &-form,
            select {
                width: 100%;
            }
        }

        .table-basic.checkout-payment-option {
            margin-top: 30px;
            margin-bottom: 20px;
            @include media-sp {
                margin-top: calcP(30);
                margin-bottom: calcP(20);
            }

            th,
            td {
                background-color: #fff;
            }
        }
    }

    &__ttl {
        margin: 30px 0 10px;
        font-weight: 700;
        color: #000;

        @include media-sp {
            margin-top: calcP(30);
            margin-bottom: calcP(10);
        }

        &.no-margin {
            margin-top: 0;
        }
    }

    &__description {
        @include calc-font(14, 21);

        @include media-sp {
            @include calc-fontvw(14, 21);
        }
    }

    &-button-wrapper {
        margin-top: 30px;
        text-align: center;

        @include media-sp {
            margin-top: calcP(30);

            .button-basic {
                width: 100%;
            }
        }
    }
}

.table-basic {
    .col-text-notactive {
        color: #ccc;
    }

    a {
        text-decoration: underline;
    }
}

.checkout-payment-option-applied-button-wrapper {
    margin-top: -30px;
    text-align: right;

    .button-text {
        margin-left: auto;
    }
}

.jsPaymentOption {
    display: none;

    &.active {
        display: block;
    }
}

.jsAppliedPaymentOption {
    display: none;

    &.active {
        display: block;
    }
}
