.slider {
    position: relative;
    width: 100%;
    max-height: 85vh;
    vertical-align: top;
    font-size: 0;

    & > li:not(:first-child) {
        display: none;
    }
}

.slick-slide {
    img {
        display: initial;
    }
}

.slick-dots {
    position: absolute;
    bottom: 21.5px;
    width: 100%;
    height: 3px;
    z-index: 10;
    text-align: center;
    font-size: 0;

    @include media-sp {
        bottom: 3.6vw;
    }

    & li {
        display: inline-block;

        &:nth-child(n+2) {
            margin-left: 10px;

            @include media-sp {
                margin-left: calcP(10);
            }
        }
    }

    & button {
        color: transparent;
        outline: none;
        width: 35px;
        height: 3px;
        display: block;
        width: 35px;
        background: #ccc;

        // タッチエリア対策
        margin-bottom: 20px;
    }

    & .slick-active button {
        background: #264064;
    }
}
