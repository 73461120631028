.signin-content {
    &-wrapper {
        margin-top: 70px;
        display: flex;
        justify-content: space-between;

        @include media-sp {
            display: block;
            margin-top: 0;
            padding-top: calcP(20);

            .modal-new-address-form-wrapper {
                padding-top: 0;
            }
        }

        button.button-basic {
            margin-top: 30px;
            width: 100%;

            @include media-sp {
                margin-top: calcP(20);
            }

            &.signin-button-apply {
                margin-top: 40px;

                @include media-sp {
                    margin-top: calcP(40);
                }
            }
        }
    }

    padding: 0 9.6774%;
    width: 50%;
    box-sizing: border-box;

    @include media-sp {
        display: none;
        padding: 0;
        width: 100%;

        &.active {
            display: block;
        }
    }

    &.first {
        border-right: 1px solid #e8e8e8;

        @include media-sp {
            border-right: none;
        }
    }

    &__ttl {
        padding-bottom: 10px;
        text-align: center;
        font-weight: 500;
        @include calc-font(24, 24);

        color: #000;
    }

    &__description {
        margin-top: 20px;
        @include calc-font(14, 21);

        @include media-sp {
            margin-top: calcP(20);
            @include calc-fontvw(14, 21);
        }
    }

    &-tab {
        @include media-sp {
            margin-top: calcP(40);
            display: flex;
            justify-content: center;

            li {
                &:first-child {
                    margin-right: calcP(60);
                }

                .button-text {
                    position: relative;
                    padding-bottom: 10px;
                    color: #acacac;
                    font-weight: 500;
                    @include calc-fontvw(18, 18);

                    box-sizing: border-box;

                    &.active {
                        color: #000;
                    }
                }
            }
        }
    }
}

.signin-complete-wrapper {
    margin-top: 80px;
    text-align: center;

    @include media-sp {
        margin-top: calcP(44);
    }
}

.signin-complete__img {
    margin-bottom: 40px;

    @include media-sp {
        margin-bottom: calcP(30);
    }
}

.checkout-complete__description--signin {
    text-align: left;
    @include media-pc {
        margin-right: auto;
        margin-left: auto;
        max-width: 560px;
    }
}
