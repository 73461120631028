// ------ weights ------
:root {
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 700;
    --font-weight-black: 900;
}

.regular {
    font-weight: var(--font-weight-regular);
}

.medium {
    font-weight: var(--font-weight-medium);
}

.bold {
    font-weight: var(--font-weight-bold);
}

.black {
    font-weight: var(--font-weight-black);
}

// ------ align ------
body {
    .align {
        &-left {
            text-align: left;
        }

        &-center {
            text-align: center;
        }

        &-right {
            text-align: right;
        }
    }
}

// ------ title size ------
.main-content__ttl {
    @include calc-font(36, 48);

    color: #000;

    @include media-sp {
        @include calc-fontvw(32, 43);
    }

    &.is-margintop {
        margin-top: 45px;
    }
}
