.custom-select {
    display: flex;
    align-items: center;
    &-form {
        position: relative;
        z-index: 1;

        @include media-sp {
            width: 80px;
            height: 44px;
        }

        &::after {
            position: absolute;
            content: "";
            margin-top: -3px;
            width: 11px;
            height: 7px;
            right: 10px;
            top: 50%;
            background: url(../images/common/icon_arrow_pulldown.svg) no-repeat left top;
            background-size: contain;
            z-index: 2;
            pointer-events: none;
        }

        &.disabled {
            &::after {
                background-image: url(../images/common/icon_arrow_pulldown_disabled.png);
            }
        }

        > select {
            position: relative;
            padding: 11px 30px 11px 12px;
            min-width: 80px;
            min-height: 44px;
            color: #000;
            appearance: none;
            background: none;
            background-color: #fff;
            border: 1px solid #e8e8e8;
            @include calc-font(16, 19);
            box-sizing: border-box;

            @include media-sp {
                position: absolute;
                top: 0;
                left: 0;
            }

            &:focus {
                border: 1px solid #264064;
            }

            &:disabled {
                color: #acacac;
                background-color: #f9f9f9;
            }
        }

        > select.is-invalid {
            border-color: #ff006c;
            background: #ffeff6;
        }
    }

    label {
        margin-right: 15px;
        color: #000;
    }
}

button {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;

    @include media-pc {
        transition: 0.3s;
    }

    &:disabled {
        color: #F1F1F1;
        background-color: #ACACAC;
        cursor: default;
    }
}

.button-basic {
    padding: 12px 0;
    text-align: center;
    min-width: 230px;
    @include calc-font(14, 19);
    font-weight: 500;
    color: #fff;
    background-color: #264064;
    border-radius: 6px;

    @include media-pc {
        &:not(:disabled):hover {
            background-color: #667992;
        }
    }

    @include media-sp {
        min-width: initial;
        box-sizing: border-box;
        @include calc-fontvw(14, 19);
    }
}

.button-gray {
    border-radius: 6px;
    padding: 12px 0;
    text-align: center;
    min-width: 230px;
    @include calc-font(14, 19);
    font-weight: 500;
    background-color: #f1f1f1;
    @extend %img-filter;

    @include media-pc {
        &:not(:disabled):hover {
            background-color: #ddd;
            color: #000;
        }
    }

    @include media-sp {
        border-radius: 6px;
        min-width: initial;
        padding-left: 54px;
        padding-right: 54px;
        box-sizing: border-box;
        @include calc-fontvw(14, 19);
    }

    &:disabled {
        color: #cccccc;
        background-color: #f1f1f1;
        cursor: default;
    }
}

.button-change {
    padding: 7px 11px;
    height: 32px;
    line-height: 32px;
    @include calc-font(14, 14);
    font-weight: 500;
    border: 1px solid #e8e8e8;
    border-radius: 5px;

    @include media-pc {
        transition: 0.3s;
        &:hover {
            color: #000;
            border: 1px solid #f1f1f1;
            background-color: #f1f1f1;
        }
    }

    @include media-sp {
        padding-top: 8px;
        padding-bottom: 8px;
        height: auto;
        @include calc-fontvw(14, 14);
    }

    img {
        margin-right: 5px;
        display: inline-block;
    }

    @extend %img-filter;
}

.button-icon {
    @include media-pc {
        transition: 0.3s;

        &:hover {
            color: #000;
            background-color: #f1f1f1;
        }
    }

    img {
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
    }

    @extend %img-filter;

    &.is-border {
        padding: 13px 15px;
        @include calc-font(14, 14);
        font-weight: 500;
        line-height: 1;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 6px;

        @include media-pc {
            &:hover {
                background-color: #f1f1f1;
                border: 1px solid #f1f1f1;
            }
        }

        @include media-sp {
            padding: calcP(13) calcP(15);
            @include calc-fontvw(14, 14);
        }
    }

    &--icon-only {
        img {
            margin-right: 0;
        }

        @include media-pc {
            &:hover {
                background-color: transparent;
                border: none;
            }
        }
    }
}

.button-text {
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: 500;
    @extend %img-filter;

    @include media-pc {
        transition: 0.3s;

        &:hover {
            color: #000;
        }
    }

    img {
        margin-right: 3px;
        display: inline-block;

        @include media-pc {
            transition: 0.3s;
        }
    }

    @include media-pc {
        &:hover {
            text-decoration: underline;
        }
    }

    @include media-sp {
        @include calc-fontvw(14, 14);
    }

    &--image-right {
        img {
            margin-right: 0;
            margin-left: 7px;
        }
    }
}

.button-image {
    padding: 0;
    line-height: 1;

    @include media-pc {
        transition: 0.3s;
        &:hover {
            opacity: 0.7;
        }
    }
}

.custom-checkbox {
    input[type="checkbox"] {
        display: none;

        &:checked + label {
            &::before {
                background-color: #264064;
                border: none;
            }
        }
        &:disabled + label {
            color: #ACACAC;
            &::before {
                background-color: #F1F1F1;
                border: none;
            }
            &::after {
                display: none;
            }
        }
    }

    label {
        position: relative;
        cursor: pointer;
        color: #666;

        &::before {
            content: "";
            margin-right: 10px;
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 3px;
            border: 1px solid #ddd;
            vertical-align: middle;
            box-sizing: border-box;
            transition: 0.3s;
        }

        &::after {
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            content: "";
            display: block;
            height: 7px;
            left: 7px;
            margin-top: -5px;
            position: absolute;
            top: 50%;
            transform: rotate(45deg);
            width: 5px;
        }
    }
}
.input__text {
    padding: 10px;
    height: 44px;
    line-height: 44px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 0;
    @include calc-font(16, 19);

    @include media-sp {
        @include calc-fontvw(16, 16);
    }
}

.input__search {
    padding: 10px 10px 10px 50px;
    height: 44px;
    line-height: 44px;
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    min-width: 304px;
    @include calc-font(16, 19);

    &-form {
        position: relative;

        &::before {
            content: "";
            background: url(../images/common/icon_search.svg) no-repeat 50% 50%;
            background-size: contain;
            display: block;
            position: absolute;
            left: 19px;
            top: 13px;
            width: 18px;
            height: 19px;
        }
    }

    @include media-sp {
        @include calc-fontvw(16, 19);
        -webkit-appearance: none;
        border-radius: 0;
        background: #FFFFFF;
    }

    &:focus {
        border: 1px solid #264064;
    }

    &:disabled {
        color: #acacac;
        background-color: #f9f9f9;
    }
}

.input__textarea {
    border-radius: 0;
    padding: 13px 15px;
    @include calc-font(16, 24);
    width: 100%;
    height: 120px;
    border: 1px solid #ddd;
    box-sizing: border-box;

    @include media-sp {
        @include calc-fontvw(16, 24);
        height: 215px;
    }
}

.input__password {
    padding-right: 36px;

    &-wrapper {
        position: relative;

        .toggle-disp-button {
            position: absolute;
            right: 8px;
            top: 8px;

            img {
                display: none;

                &.active {
                    display: inline;
                }
            }
        }
    }
}

::placeholder {
    color: #acacac;
}

.is-invalid {
    border-color: #ff006c;
    background: #ffeff6;
}

div.invalid-feedback {
    color: #ff006c;
    display: block;
    @include calc-font(14, 32);

    @include media-sp {
        @include calc-fontvw(14, 32);
    }
}

div.success-feedback {
    color: #000;
    display: block;
    @include calc-font(14, 32);

    @include media-sp {
        @include calc-fontvw(14, 32);
    }
}
