.myaccount-row-wrapper {
    display: flex;

    @include media-sp {
        display: block;
    }
}

.myaccount-row-nav {
    width: 304px;
    order: 1;

    @include media-sp {
        width: 100%;
    }
}

.myaccount-row-content {
    position: relative;
    margin-left: 65px;
    flex: 1;
    order: 2;
    

    @include media-sp {
        margin-left: 0;
    }

    @include print {
        min-width: 550px;
    }

    .modal-address-book-wrapper {
        padding: 0;
        width: 100%;
    }
}

.myaccount__nav-list {
    @include media-sp {
        margin-top: calcP(70);
    }
    li {
        a {
            position: relative;
            display: block;
            width: 100%;
            padding: 12px 10px 16px;
            text-align: left;
            box-sizing: border-box;
            background-color: #fff;
            @extend %img-filter;
            border-top: 1px solid #e8e8e8;
            @include calc-font(16, 19);

            @include media-sp {
                padding: calcP(14) calcP(10) calcP(16);
                @include calc-fontvw(16, 19);
            }

            img {
                position: absolute;
                right: 10px;
                top: 50%;
                margin-top: -5px;

                @include media-sp {
                    right: calcP810;
                }
            }

            @include media-pc {
                &:hover {
                    text-decoration: none;
                    background-color: #f1f1f1;
                }
            }

            &.active {
                color: #000;
                background-color: #f1f1f1;

                img {
                    filter: none;
                }
            }
        }

        &:last-child {
            a {
                border-bottom: 1px solid #e8e8e8;
            }
        }
    }
}

.cart__product-list--myaccount {
    padding-left: 0;
    .cart__product-list-article-detail-wrapper {
        flex: 1;
    }

    .cart__product-list-article-checkout {
        flex-basis: initial;
    }

    .button-text-only {
        @include media-sp {
            margin-left: auto;
            margin-top: calcP(20);
        }
    }

    .button-gray {
        border-radius: 6px;
    }
}

.cart__product-list--mywaitlist {
    padding-top: 50px;
    .cart__product-list-article {
        &:first-child {
            border-top: 1px solid #e8e8e8;
        }
    }

    .cart__product-list-article-checkout {
        @include media-sp {
            margin-top: calcP(20);
            padding-right: 0;

            &-nomargin {
                margin-top: 0;
            }

            .button-basic {
                margin-top: 0;
            }

            .button-gray {
                width: 100%;
            }
        }
        button:first-child {
            @include media-pc {
                margin-right: 0;
            }
        }
    }

    .cart__product-list-article-checkout-action {
        .button-gray {
            @include media-sp {
                width: 100%;
            }
        }
    }
}

.cart__product-list-article-detail-notice {
    margin-top: 5px;
    @include calc-font(14, 21);

    @include media-sp {
        @include calc-fontvw(12, 18);
    }
}

.cart__ttl--myaccount {
    margin-top: 80px;
}

.myaccount-information {
    &-ttl-wrapper {
        padding-bottom: 10px;

        @include media-sp {
            padding-bottom: 0;
        }
    }
    .modal-new-address-form-parts.half {
        @include media-pc {
            width: 48.90929965556831%;
        }
    }

    &__description {
        margin-top: 30px;
        @include calc-font(16, 24);

        @include media-sp {
            margin-top: calcP(30);
            @include calc-fontvw(16, 24);
        }
    }

    &-update-button {
        margin-top: 40px;
        text-align: center;

        @include media-sp {
            margin-top: calcP(40);
        }

        .button-basic {
            margin-right: auto;
            margin-left: auto;
            padding-top: 18px;
            padding-bottom: 18px;
            width: 372px;

            @include media-sp {
                width: 100%;
            }
        }
    }
    &-error-message {
        border: 2px solid #FF006C;
        color: #FF006C;
        max-width: 570px;
        margin: 0 auto;
        margin-top: 50px;
        @include calc-font(14, 21);
        padding: 20px;
        @include media-sp {
            margin-bottom: calcP(40);
            @include calc-fontvw(14, 21);
        }
    }
}

@include print {        
    .jsUnderLine .underline {
        display: none!important;
    }
    
    .mypoints-content-wrapper {

        .mypoints-point__list li span.date {
            min-width: 150px;
        }

        .mypoints-point__list li span.trigger {
            min-width: 238px;
        }

        .spOnly {
            display: none;
        }
    }
}
