.information-content {
    .information-list {
        border-top: 1px solid #e8e8e8;
        max-width: 872px;
        margin: 50px auto 0;

        @include media-sp {
            margin-top: calcP(40);
        }

        &__item {
            border-bottom: 1px solid #e8e8e8;

            a {
                display: block;
                @include calc-font(16, 35);

                padding: 10px 0;

                @include media-sp {
                    @include calc-fontvw(16, 24);
                }
            }
        }
    }
    .not-content {
        text-align: center;
        color: #ccc;
        margin-top: 60px;
        font-size: 1.75rem;
        line-height: 1.1785714286;
        font-weight: var(--font-weight-bold);
    }
}

.information-details-content-wrapper {
    max-width: 892px;
    margin: 0 auto;

    .main-content__ttl {
        @include calc-font(28, 33.6);

        @include media-sp {
            @include calc-fontvw(22, 25);
        }
    }

    .information-details-date {
        color: #000;
        font-weight: var(--font-weight-regular);
        @include calc-font(18, 21);

        margin-top: 20px;

        @include media-sp {
            @include calc-fontvw(18, 21);

            margin-top: 15px;
        }
    }

    .information-details-content {
        margin-top: 50px;

        @include media-sp {
            margin-top: calcP(40);
        }

        &__text {
            font-weight: var(--font-weight-regular);
            @include calc-font(16, 24);

            margin-top: 20px;

            @include media-sp {
                @include calc-fontvw(16, 24);
            }
        }
    }

    button.button-back {
        display: block;
        margin: 80px auto 0;
        width: 372px;
        padding: 18px 0;
        text-align: center;
        min-width: 230px;
        @include calc-font(14, 16);

        font-weight: 500;
        background-color: #f1f1f1;
        border-radius: 6px;
        @extend %img-filter;

        @include media-pc {
            &:not(:disabled):hover {
                background-color: #ddd;
                color: #000;
            }
        }

        @include media-sp {
            min-width: initial;
            padding-left: 54px;
            padding-right: 54px;
            box-sizing: border-box;
            @include calc-fontvw(14, 16);

            margin-top: calcP(70);
            width: 100%;
        }
    }
}
