.mypoints__description {
    @include calc-font(14, 21);

    @include media-sp {
        @include calc-fontvw(14, 21);
    }
}

.mypoints__first {
    margin-top: 20px;

    @include media-sp {
        margin-top: calcP(20);
    }
}

.mypoints__title {
    float: left;
}

.mypoints-content-tab {
    margin-top: 40px;
    display: flex;
    justify-content: center;

    @include media-sp {
        margin-top: calcP(30);
    }

    li {
        margin-right: 40px;

        @include media-sp {
            margin-right: calcP(40);
            @include calc-fontvw(16, 16);
        }

        &.last {
            margin-right: 0;
        }
    }

    &-button {
        padding-bottom: 7px;
        @include calc-font(16, 16);
        color: #acacac;
        font-weight: 500;

        &.active {
            color: #000;
        }
    }
}

.mypoints-content {
    display: none;

    &.active {
        display: block;
    }
}

.mypoints-point__list {
    padding-top: 25px;

    @include media-sp {
        padding: 15px 0;
        border-top: 1px solid #e8e8e8;

        &.last {
            border-bottom: 1px solid #e8e8e8;
        }

        &-wrapper {
            margin-top: calcP(30);
        }

        &.gray {
            li {
                color: #ccc;
            }
        }
    }

    &--no-ttl {
        padding-top: 35px;

        li {
            &:first-child {
                border-top: 1px solid #e8e8e8;
            }
        }
    }

    li {
        padding: 15px 0;
        display: flex;
        border-bottom: 1px solid #e8e8e8;

        @include media-sp {
            padding: 0;
            margin-top: 5px;
            border-bottom: none;
        }

        &.ttl {
            font-weight: 500;
        }

        &.gray {
            color: #ccc;
        }

        span {
            display: inline-block;

            a {
                cursor: pointer;
                text-decoration: underline;
            }

            @include media-pc {
                &.date {
                    flex-basis: 198px;
                }

                &.trigger {
                    flex-basis: 238px;
                }

                &.grant {
                    flex-basis: 111px;
                    text-align: right;
                }

                &.disabled {
                    flex-basis: 111px;
                    text-align: right;
                    text-decoration: line-through;
                }

                &.use {
                    flex-basis: 142px;
                    text-align: right;
                }

                &.valid {
                    flex-basis: 183px;
                    text-align: right;
                }
            }

            @include media-sp {
                @include calc-fontvw(16, 24);
                &.ttl {
                    flex-basis: 38.80597014925373%;
                    text-align: left;
                    font-weight: 500;
                }

                &.content {
                    margin-left: 20px;
                    flex-basis: 61.49253731343284%;
                    text-align: left;
                }
                &.content-disabled {
                    margin-left: 20px;
                    flex-basis: 61.49253731343284%;
                    text-align: left;
                    text-decoration: line-through;
                }
            }
        }
    }

    &-3clm {
        li {
            justify-content: space-between;
        }
    }
}

.modal-mypoints-expiration-wrapper {
    padding-right: 40px;
    padding-left: 40px;
    width: 800px;

    @include media-sp {
        padding-right: calcP(20);
        padding-left: calcP(20);
        width: 100%;

        .button-gray {
            width: 68.6567%;
        }
    }
}

.modal-mypoints-exxpiration__ttl {
    text-align: left;
}

.modal-mypoints__expiration-table {
    margin-top: 15px;
    margin-bottom: 40px;
    width: 100%;
    @include media-sp {
        margin-top: calcP(15);
        margin-bottom: calcP(30);
    }

    thead {
        th {
            font-weight: 500;
        }
    }

    th,
    td {
        padding: 15px 0;
        text-align: left;
        border-bottom: 1px solid #e8e8e8;

        @include media-sp {
            padding: calcP(15) 0;
            @include calc-fontvw(16, 16);
        }

        &.valid {
            width: 250px;

            @include media-sp {
                width: 42.08955223880597%;
            }
        }

        &.trigger {
            width: 258px;

            @include media-sp {
                width: 40%;
            }
        }

        &.points {
            padding-right: 150px;
            text-align: right;

            @include media-sp {
                padding-right: 0;
            }
        }

        a {
            text-decoration: underline;
        }
    }
}
