body {
    @include media-sp {
        &.jsModalOpenedIOS {
            position: fixed;
            width: 100%;
            overscroll-behavior-y: none;
        }

        &.jsModalOpened {
            overflow: hidden;
        }
    }
}

.mfp-wrap {
    overflow: scroll;
}

.mfp-bg {
    background: #000;
    opacity: 0.5;

    @include media-sp {
        background-color: #fff;
        opacity: 1;
    }
}

.mfp-bg.mfp-fade.mfp-ready {
    opacity: 0.5;

    @include media-sp {
        background-color: #fff;
        opacity: 1;
    }
}

.mfp-container {
    @include media-sp {
        padding: 0;
    }
}

.mfp-content {
    @include media-sp {
        vertical-align: top;
    }
}

.modal-base {
    position: relative;
    margin: 0 auto;
    background-color: #fff;
}

.mfp-close.mfp-close--custom {
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;

    &:active {
        top: 5px;
    }

    @extend %img-filter;
}

.modal-new-address {
    &-wrapper {
        padding: 40px 40px 50px;
        width: 800px;
        box-sizing: border-box;

        @include media-sp {
            padding: calcP(40) calcP(20) calcP(50);
            width: 100%;
        }
    }

    &__ttl {
        @include calc-font(32, 32);

        font-weight: 900;
        color: #000;

        @include media-sp {
            @include calc-fontvw(28, 28);
        }
    }

    &-form {
        &-wrapper {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;

            @include media-sp {
                display: block;
                margin-top: 0;
                padding-top: 10px;
            }

            .input__text,
            .custom-select-form,
            select {
                width: 100%;
            }
        }

        &__ttl {
            margin-bottom: 10px;
            @include calc-font(14, 14);

            @include media-sp {
                margin-bottom: calcP(10);
                @include calc-fontvw(14, 14);
            }
        }

        &-parts {
            width: 100%;

            @include media-sp {
                margin-top: calcP(20);
            }

            &.half {
                width: 350px;

                @include media-sp {
                    width: 100%;
                }
            }

            .input__text-error {
                display: none;
            }

            &__error {
                input.input__text {
                    &:required {
                        border-color: #ff006c;
                        background: #ffeff6;

                        + .input__text-error {
                            color: #ff006c;
                            display: block;
                            @include calc-font(14, 32);

                            @include media-sp {
                                @include calc-fontvw(14, 32);
                            }
                        }
                    }
                }

                .custom-select {
                    &-form {
                        > select {
                            &:required {
                                border-color: #ff006c;
                                background: #ffeff6;
                            }
                        }
                    }

                    &__error {
                        + .input__text-error {
                            color: #ff006c;
                            display: block;
                            @include calc-font(14, 32);

                            @include media-sp {
                                @include calc-fontvw(14, 32);
                            }
                        }
                    }
                }
            }
        }

        &-checkbox {
            margin-top: 30px;

            @include media-sp {
                margin-top: calcP(20);
            }

            label {
                @include calc-font(14, 14);

                @include media-sp {
                    @include calc-fontvw(14, 14);
                }
            }
        }
    }

    &-buttons {
        margin-top: 50px;
        display: flex;
        justify-content: center;

        @include media-sp {
            display: block;
            margin-top: 0;
            padding-top: calcP(20);
        }

        button {
            margin-right: 20px;
            width: 250px;

            @include media-sp {
                margin-top: calcP(20);
                margin-right: 0;
                width: 100%;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.modal-address-book {
    &-wrapper {
        padding: 40px;
        width: 800px;
        box-sizing: border-box;

        @include media-sp {
            padding: calcP(40) calcP(20);
            width: 100%;
        }
    }

    &__ttl {
        margin-bottom: 28px;
        padding-bottom: 0;
        @include calc-font(32, 32);

        font-weight: 900;
        color: #000;

        @include media-sp {
            margin-bottom: caclp(28);
            @include calc-fontvw(28, 28);
        }

        &--myaccount {
            margin-bottom: 50px;
            @include calc-font(36, 36);

            @include media-sp {
                margin-bottom: calcP(40);
                @include calc-fontvw(32, 32);
            }
        }
    }

    &__sttl {
        margin-bottom: 28px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e8e8e8;

        @include media-sp {
            padding-bottom: calcP(20);
            margin-bottom: calcP(15);
        }
    }

    &-add {
        &-wrapper {
            text-align: center;
        }
    }

    &-list {
        margin-top: 30px;

        @include media-sp {
            margin-top: calcP(15);
        }

        &__article {
            display: flex;
            align-items: center;
            padding: 16px 0;
            border-top: 1px solid #e8e8e8;

            @include media-sp {
                display: block;
                padding: calcP(20) 0;
            }

            &:last-child {
                border-bottom: 1px solid #e8e8e8;
            }

            &-address {
                margin-right: 10px;
                flex: 1;

                @include media-sp {
                    margin-right: 0;
                }

                &-label {
                    margin-bottom: 8px;
                    line-height: 1;

                    @include media-sp {
                        margin-bottom: 3px;
                    }
                }

                &-name {
                    font-weight: 500;
                    @include media-sp {
                        @include calc-fontvw(16, 21);
                    }
                }

                &-txt {
                    margin-top: 5px;
                    @include calc-font(14, 21);

                    @include media-sp {
                        @include calc-fontvw(14, 21);
                    }
                }
            }

            &-buttons {
                display: flex;
                align-items: center;

                @include media-sp {
                    margin-top: calcP(10);
                }
            }

            &-edit,
            &-select {
                @include calc-font(14, 14);

                line-height: 1;

                @include media-sp {
                    @include calc-fontvw(14, 14);
                }
            }

            &-edit,
            &-delete {
                margin-right: 10px;

                &.is-border {
                    padding: 9px 15px 8px;

                    @include media-sp {
                        padding: 8.5px 15px;
                    }
                }

                img {
                    margin-right: 2px;
                    vertical-align: middle;
                }
            }

            &-delete {
                @include media-sp {
                    &.is-border {
                        padding-top: 7.925px;
                        padding-bottom: 7.925px;
                    }
                }

                img {
                    margin-top: -2px;
                }
            }

            &-select {
                padding: 10px 0;
                width: 155px;
                min-width: initial;

                @include media-sp {
                    padding: 9px 15px;
                    width: auto;
                }
            }
        }
    }
}

.modal-product-wrapper {
    padding: 36px 0;
    width: 496px;
    text-align: center;
    box-sizing: border-box;
}

.modal-product__description {
    width: 95%;
    margin: 10px;
    line-height: normal;
}

.modal-paypal-buttons, .modal-product-buttons, .modal-order-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    @include media-sp {
        display: block;
        margin-top: calcP(30);
    }

    li {
        &:first-child {
            margin-right: 18px;

            @include media-sp {
                margin-right: 0;
                margin-bottom: calcP(20);
            }
        }
    }

    button {
        min-width: 180px;

        @include media-sp {
            width: 100%;
        }
    }
}

.modal-payment-failed-buttons {
    padding-top: 10px;

    @include media-sp {
        padding-top: calcP(10);
    }

    li {
        margin-top: 20px;

        button {
            min-width: 250px;

            @include media-sp {
                width: 100%;
            }
        }

        @include media-sp {
            margin-top: calcP(20);
        }
    }
}

.modal-single-button {
    margin-top: 40px;

    @include media-sp {
        margin-top: calcP(50);
    }

    button {
        min-width: 240px;

        @include media-sp {
            width: 100%;
        }
    }
}
