.jsCardFadeIn {
  opacity: 0;
  transition: opacity 0.6s ease-out;

  a {
    transform: translateY(150px);
    transition: transform 0.6s ease-out;
  }

  @include media-sp {
    a {
      transform: translateY(40px);
    }
  }

  @media print {
    opacity: 1;

    a {
      transform: none;
    }
  }

  &.is-active {
    opacity: 1.0;

    a {
      transform: translateY(0);
    }
  }
}


.jsCardFadeInNews {
  opacity: 0;
  transform: translateY(150px);

  @include media-sp {
    transform: translateY(40px);
  }

  @media print {
    opacity: 1;
    transform: none;
  }

  &.is-active {
    opacity: 1.0;
    transform: translateY(0);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
}
