.filter {
    &-bar {
      width: 100%;
      border-top: 1px solid #E8E8E8;
      border-bottom: 1px solid #E8E8E8;

      @include media-sp {
        margin-top: 20px;
      }
    }
    &__inner {
      position: relative;
      display: flex;
      min-height: 74px;
      align-items: center;

      @media screen and (max-width: 1080px) {
        align-items: flex-start;
      }

      @include media-sp {
        align-items: center;
      }

      &::before {
        content: '';
        position: relative;
        min-width: 36px;
        height: 36px;
        background: url(../../../static/images/common/icon_filter.svg) no-repeat;
        background-size: contain;
        margin-right: max(min(calc(2px + calc(48 * calc(100vw - 1164px) / calc(1920 - 1164))), 30px), 20px);

        @media screen and (max-width: 1080px) {
          top: 19px;
        }

        @include media-sp {
          display: none;
        }

      }
    }
    &__button-sp {
      display: none;

      @include media-sp {
        position: relative;
        display: flex;
        align-items: center;
        width: 100px;
        height: 44px;
        color: #000000;
        @include calc-font(16, 21);
        font-weight: var(--font-weight-medium);
        border-radius: 6px;
        border: 1px solid #E8E8E8;

        &::before {
          content: '';
          position: relative;
          left: 4px;
          width: 36px;
          height: 36px;
          margin-right: 10px;
          background: url(../../../static/images/common/icon_filter.svg) no-repeat;
          background-size: contain;
        }

      }

    }
  }
  // filter上部層
  .filter-main {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    min-height: 74px;
    box-sizing: border-box;

    @media screen and (max-width: 1080px) {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 20px;
    }

    @include media-sp {
      flex-direction: row;
      width: 100%;
      padding: 15px 0;
    }

    &__list {
      position: relative;

      @include media-sp {
        display: none;
      }

      ul {
        display: flex;

        li {
          position: relative;
          padding: 26px 0 27px 0;
          box-sizing: border-box;
          font-weight: var(--font-weight-medium);
          @include calc-font(16, 21);
          white-space: nowrap;
          color: #000000;

          &:not(:last-child) {
            margin-right: max(min(calc(2px + calc(48 * calc(100vw - 1224px) / calc(1920 - 1224))), 30px), 20px);
          }

          @media screen and (max-width: 1080px) {
            padding-bottom: 22px;
          }

          img {
            margin-left: 7px;
            transition: transform 0.3s ease-out;
          }

        }
      }
      &-selected {
        position: absolute;
        left: 0;
        top: 53px;
        width: 1px;
        height: 2px;
        background-color: #000000;
        transition: all 0.3s ease-out;
        opacity: 0;

        @media screen and (max-width: 1080px) {
          top: 50px;
        }

        @include media-sp {
          display: none;
        }
      }
    }
    // 並び替えソート
    &__sort {
      position: relative;
      margin-left: auto;

      @media screen and (max-width: 1080px) {
        order: 2;
        position: absolute;
        top: 15px;
        right: 0;
      }

      @include media-sp {
        @include calc-font(16, 16);
        position: relative;
        top: 0;
        width: 177px;
        height: 44px;
      }

      &::after {
        content: '';
        position: absolute;
        display: block;
        margin-top: -3px;
        top: 50%;
        right: 11px;
        width: 11px;
        height: 7px;
        background: url(../../../static/images/common/icon_arrow_pulldown.svg) no-repeat left top;
        background-size: contain;
      }

      & select {
        position: relative;
        padding: 10px 30px 11px 15px;
        min-width: 120px;
        min-height: 44px;
        appearance: none;
        background: none;
        color: #000000;
        @include calc-font(16, 21);
        text-align: left;
        border: 1px solid #E8E8E8;
        box-sizing: border-box;

        @include media-sp {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }

  // トグルスイッチ
  .filter-switch {
    @include media-sp {
      & + & {
        margin-top: 14px;
      }
    }
    &-pc {
      display: flex;
      align-items: center;
      gap: max(min(calc(2px + calc(48 * calc(100vw - 1224px) / calc(1920 - 1224))), 30px), 20px);

      // プルダウンとトグルスイッチの間
      margin-left: max(min(calc(2px + calc(48 * calc(100vw - 1164px) / calc(1920 - 1164))), 40px), 30px);

    @media screen and (max-width: 1080px) {
         margin-left: 0;
      }

      @include media-sp {
        display: none;
      }

    }
    &-sp {
      display: inline-block;
      padding: 0 0 20px 20px;
    }
    & input[type=checkbox] {
      display: none;

      &:checked + label {
        &::before {
          background-color: #264064;
        }

        &::after {
          left: 20px;
        }
      }
    }
    > label {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #666666;
      @include calc-font(16, 21);

      @include media-sp {
        @include calc-font(16, 22);
      }

      &::before {
        content: '';
        width: 38px;
        height: 20px;
        display: inline-block;
        background: #E8E8E8;
        border-radius: 10px;
        margin-right: 10px;
        vertical-align: middle;
        box-sizing: border-box;
        transition: background-color 0.2s ease-out;

        @include media-sp {
          margin-right: 14px;
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 2px;
        width: 16px;
        height: 16px;
        transform: translateY(-50%);
        border-radius: 8px;
        vertical-align: middle;
        background: #FFFFFF;
        transition: left 0.2s ease-out;
      }
    }

  }

  // PC版フィルターの中身
  .filter__menu-pc {
    position: absolute;
    display: none;
    top: 75px;
    left: 0;
    width: 100%;
    height: 314px;
    background: #FFFFFF;
    z-index: 3;
    backface-visibility: hidden;

    @media screen and (max-width: 1080px) {
      top: 64px;
    }

    @media print {
      display: none;
    }

    &__inner {
      position: relative;
      display: none;
      padding: 40px;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      backface-visibility: hidden;
    }
    &-series,
    &-characters,
    &-availability,
    &-price,
    &-title,
    &-artist,
    &-formats,
    &-title,
    &-collection,
    &-deals,

    &-scale {
      display: none;
    }

    &__keywords {
      position: absolute;
      top: 40px;
      left: calc(50% - 175px);
      height: 74px;

      .input__text {
        width: 350px;
      }
    }
    &__list {
      position: absolute;
      top: 40px;
      left: 40px;
      display: flex;
      flex-wrap: wrap;
      max-height: 160px;
      gap: 10px 40px;
      overflow: auto;
    }

    &-series &__list,
    &-characters &__list,
    &-collection &__list {
      top: 114px;
    }

    .is-scroll-bar &__list,
    .is-scroll-bar &__list {
      overflow: scroll;
    }

    .is-scroll-bar &__list::-webkit-scrollbar,
    .is-scroll-bar &__list::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    .is-scroll-bar &__list::-webkit-scrollbar-thumb,
    .is-scroll-bar &__list::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,.5);
      box-shadow: 0 0 1px rgba(255,255,255,.5);
    }

    .filter__menu-pc-price {
      .filter__menu-pricerange {
        position: absolute;
        top: 40px;
        left: calc(50% - 186px);
        right: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 0 10px;
        z-index: 10;
        height: 74px;

        &__unit {
          display: flex;
          align-items: center;
          height: 40px;
          @include calc-font(14, 35);
        }

        input {
          height: 40px;
          max-width: 130px;
          @include calc-font(16, 19);
        }
      }
      .filter__menu-pc__list {
        top: 114px;
      }

      //価格レンジ
        .filter__menu-pc-price .filter__menu-pricerange {
            position: absolute;
            top: 40px;
            left: calc(50% - 186px);
            right: auto;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
            gap: 0 10px;
            z-index: 10;
            height: 74px
        }

        //価格レンジエラーメッセージ
        .price-error {
            position: absolute;
            top: 75px;
            left: calc(50% - 186px);
            right: auto;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
            gap: 0 10px;
            z-index: 10;
            height: 74px
        }
        .input__price-error {
            color: #FF006C;
            @include calc-font(14, 32);

            @include media-sp {
                @include calc-fontvw(14, 32);
            }
        }

        .filter__menu-pc-price .filter__menu-pricerange__unit {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            height: 40px;
            font-size: .875rem;
            line-height: 2.5
        }

        .filter__menu-pc-price .filter__menu-pricerange input {
            height: 40px;
            max-width: 130px;
            font-size: 1rem;
            line-height: 1.1875
        }

        .button-submit {
            border: 1px solid #ddd;
            border-radius: 6px;
            background: #fff;
            color: #666;
            display: block;
            height: 40px;
            width: 70px;
        }
    }
  }


  // SP版フィルター
  .filter__menu-sp {
    @include media-pc {
      display: none;
    }

    @media print {
      display: none;
    }

    @include media-sp {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: #FFFFFF;
      // headerを隠す
      z-index: 100;
      overflow-y: scroll;
    }

    &__close {
      position: fixed;
      right: 10px;
      top: 12px;
      width: 36px;
      height: 36px;
      z-index: 10;

      & > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 2px;
        background-color: #666666;
        transition: transform 0.3s ease-out, left 0.3s ease-out, top 0.3s ease-out;
        transform-origin: center center;

        &:nth-child(1) {
            transform: rotate(45deg);
            left: 9px;
            top: (11px + 6);
        }

        &:nth-child(2) {
            transform: rotate(-45deg);
            left: 9px;
            top: (11px + 6);
        }
      }
    }

    &__head {
      position: fixed;
      display: flex;
      align-items: center;
      width: 100%;
      height: 64px;
      @include calc-font(20, 26);
      font-weight: var(--font-weight-medium);
      color: #000000;
      background-color: #FFFFFF;
      z-index: 10;
      gap: 10px;

      img {
        margin-left: 10px;
      }

    }
    &-base {
      position: relative;
      padding-top: 94px;
      padding-bottom: 74px + 110;
      overflow-y: scroll;
    }

    &__accordion {
      border-top: 1px solid #E8E8E8;

      > li {
        user-select: none;
        @include calc-fontvw(16,16);
        font-weight: var(--font-weight-medium);
        color: #000000;

        > span {
          position: relative;
          display: block;
          padding: 14px 20px 15px;
          width: 100%;
          box-sizing: border-box;
          cursor: pointer;

          img {
            position: absolute;
            top: 50%;
            right: 20px;
            margin-top: -5px;
            transition: transform 0.3s;
          }
          &.active {
            img {
              transform: rotate(180deg);
            }
          }

        }

        &:last-child {
          border-bottom: 1px solid #E8E8E8;
        }
      }
      > li + li {
        border-top: 1px solid #E8E8E8;
      }

    }

    // アコーディオンの中身
    &__inner {
      position: relative;
      display: none;
      backface-visibility: hidden;

      // アコーディオンの右端のタップエリア
      & > .accordion-close-area {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        width: 50px;
        height: 100%;
      }
    }
    &__contents {
      padding: 30px 20px;
    }
    &__keywords {
      margin-top: -26px;
      padding-bottom: 30px;
      font-size: 16px;
      .input__text {
        width: 100%;
        font-size: 1em;
      }
    }
    &__list {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .filter__menu-pricerange {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0 10px;
      height: 44px;
      margin-bottom: 15px;
      margin-top: -26px;
      @include calc-fontvw(16, 19);
      font-weight: var(--font-weight-regular);

      &__unit {
        color: #666666;
        display: flex;
        align-items: center;
        height: 44px;
        @include calc-fontvw(14, 35);
        font-weight: var(--font-weight-regular);
      }

      input {
        height: 44px;
        max-width: 105px;
        @include calc-fontvw(16, 19);
        font-weight: var(--font-weight-regular);
      }

      button {
        @include calc-fontvw(14, 16);
        font-weight: var(--font-weight-medium);
        height: 44px;
        width: 50px;
      }

      .button-submit {
        border: 1px solid #ddd;
        border-radius: 6px;
        background: #fff;
        color: #666;
        display: block;
        height: 40px;
        width: 50px;
    }

      //価格レンジエラーメッセージ
      .price-error {
        position: absolute;
        top: 50px;
        left: calc(50% - 186px);
        right: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        gap: 0 10px;
        z-index: 10;
        height: 74px
      }
    }

    // 下部固定ボタンエリア
    &__button-area {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 74px;
      background-color: #F1F1F1;

      & button {
        width: 41.866vw;
        height: 44px;
        @include calc-fontvw(14, 19);
        font-weight: var(--font-weight-medium);
        border-radius: 6px;

        &:nth-child(1) {
          border: 1px solid #E8E8E8;
          background-color: #FFFFFF;
          margin-right: 20px;
        }
        &:nth-child(2) {
          border: 1px solid #264064;
          background-color: #264064;
          color: #FFFFFF;
        }
      }
    }
  }

  // フィルター内のチェックボックス
  .filter-checkbox {
    width: 310px;

    @include media-sp {
      width: 100%;
    }

    > input[type="checkbox"] {
      display: none;

      &:checked + label {
        &::before {
          background-color: #264064;
          border: none;
        }
      }
      &:disabled + label {
        color: #ACACAC;
        &::before {
          background-color: #F1F1F1;
          border: none;
        }
        &::after {
          display: none;
        }
      }
    }

    > label {
      position: relative;
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      color: #666;

      &::before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        border: 1px solid #ddd;
        vertical-align: middle;
        box-sizing: border-box;
        transition: background-color 0.3s, border 0.3s;
      }

      &::after {
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        content: "";
        display: block;
        height: 7px;
        left: 7px;
        position: absolute;
        top: 5px;
        transform: rotate(45deg);
        width: 5px;
      }
      // チェックボックスの項目が改行しても大丈夫なようにspanに入れる
      > span {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 30px);
        @include calc-font(16, 22);
        font-weight: var(--font-weight-regular);
      }
    }
  }

  // ラジオボタン
  .filter-radio {
    width: 310px;
    white-space: nowrap;

    @include media-sp {
      width: 100%;
    }

    > input[type="radio"] {
      display: none;

      &:checked + label {
        &::before {
          border-color: #264064;
        }

        &::after {
          background-color: #264064;
        }
      }
    }

    > label {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #666666;
      @include calc-font(16, 22);
      font-weight: var(--font-weight-regular);


      &::before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 1px solid #DDDDDD;
        vertical-align: middle;
        box-sizing: border-box;
        transition: border-color 0.3s;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 5px;
        display: inline;
        width: 10px;
        height: 10px;
        transform: translateY(-50%);
        border-radius: 5px;
        vertical-align: middle;
        box-sizing: border-box;
        z-index: 1;
        transition: background-color 0.3s;
      }
    }
  }
  .input__price-error-sp {
    color: #FF006C;
    @include calc-font(14, 32);

    @include media-sp {
        @include calc-fontvw(14, 32);
    }
  }
