.mfp-fade.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8;

    @include media-sp {
        opacity: 1;
    }
}

.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    transition: all 0.3s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}
