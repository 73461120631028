@use "sass:math";

@mixin clearfix {
    &:after {
        content: "";
        display: block;
        clear: both;
        visibility: hidden;
        height: 0;
    }
}

@mixin media-sp {
    @media screen and (max-width: 991px) {
        @content;
    }
}

@mixin media-pc {
    @media all and (min-width: 992px) {
        @content;
    }
}

@mixin media-tablet {
    @media screen and (max-width: 991px) and (min-width: 768px) {
        @content;
    }
}

@mixin print {
    @media print {
        @content;
    }
}

$baseFontSize: 16;
$spDesignSize: 375;
$tbDesignSize: 768;

@mixin calc-font($fsize: 16, $line-height: 26.01) {
    font-size: math.div($fsize, $baseFontSize) + 0rem;
    line-height: math.div($line-height, $fsize);
}

@mixin calc-fontvw($fsize: 16, $line-height: 26.01) {
    font-size: $fsize * math.div(100, $spDesignSize) + vw;
    line-height: math.div($line-height, $fsize);
}

@mixin calc-fontvwtb($fsize: 16, $line-height: 26.01) {
    font-size: $fsize * math.div(100, $tbDesignSize) + vw;
    line-height: math.div($line-height, $fsize);
}

@mixin margin($t, $r, $b, $l) {
    margin: math.div($t, $spDesignSize) * 100% math.div($r, $spDesignSize) * 100% math.div($b, $spDesignSize) * 100%
        math.div($l, $spDesignSize) * 100%;
}

@mixin padding($t, $r, $b, $l) {
    padding: math.div($t, $spDesignSize) * 100% math.div($r, $spDesignSize) * 100% math.div($b, $spDesignSize) * 100%
        math.div($l, $spDesignSize) * 100%;
}
@function calcP($val) {
    @return math.div($val, $spDesignSize) * 100%;
}

@function filterVal() {
    @return invert(34%) sepia(51%) saturate(0%) hue-rotate(179deg) brightness(100%) contrast(81%);
}

@function filterVal2() {
    @return invert(94%) sepia(0%) saturate(1594%) hue-rotate(314deg) brightness(75%) contrast(86%);
}

%img-filter {
    img {
        filter: invert(34%) sepia(51%) saturate(0%) hue-rotate(179deg) brightness(100%) contrast(81%);

        @include media-pc {
            transition: 0.3s;
        }
    }

    @include media-pc {
        transition: 0.3s;
        &:hover {
            img {
                filter: none;
            }
        }
    }
}
