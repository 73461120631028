.cookie {
    position: fixed;
    bottom: 0;
    left: 0;
    display: none;
    justify-content: center;
    width: 100%;
    height: 148px;
    padding: 20px 0 30px;
    background: #f1f1f1;
    box-shadow: 0 -5px 6px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    transition: all 0.3s;
    z-index: 10;

    @include media-sp {
        min-height: 238px;
        height: auto;
    }

    &__content {
        display: flex;
        width: 850px;

        @include media-sp {
            flex-direction: column;
            width: 89.33vw;
        }

        &__text {
            flex-basis: 700px;
            padding-right: 30px;

            @include media-sp {
                flex-basis: initial;
                padding: 0 0 18px 0;
            }
        }

        &__title {
            color: #000;
            @include calc-font(18, 24);

            font-weight: var(--font-weight-bold);

            @include media-sp {
                @include calc-fontvw(18, 21);
            }
        }

        &__description {
            @include calc-font(16, 24);

            @include media-sp {
                @include calc-fontvw(14, 21);
            }
        }

        &__button {
            display: flex;
            flex-direction: column;
            gap: 10px;

            @include media-sp {
                width: 89.33vw;
                flex-direction: row;
                gap: 11px;
            }

            & > .button-basic {
                flex-basis: 120px;
                min-width: 120px;

                @include media-sp {
                    min-width: initial;
                    flex: 1 0 162px;
                }
            }
        }
    }
}

.cookie-button {
    // 仮の呼び出し用
    position: fixed;
    bottom: 12px;
    left: 12px;
    padding: 10px;
    background: #e1f542;
    cursor: pointer;
    @include calc-font(14, 19);

    z-index: 10;
}
