.information-bar {
    &-inner {
        position: relative;
        width: 100%;
        min-height: 44px;
        overflow: hidden;
        display:none;
    }

    &-text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        min-height: 44px;
        text-align: center;
        @include calc-font(16, 25);

        font-weight: var(--font-weight-regular);

        @include media-sp {
            @include calc-font(14, 25);
        }
    }

    &-close {
        position: absolute;
        right: 14px;
        top: calc(50% - 10px);
        width: 20px;
        height: 20px;
        cursor: pointer;

        svg {
            width: 20px;
            height: 20px;
            transition: all 0.3s ease-out;
            vertical-align: top;
        }
    }

    a {
        &:link,
        &:visited {
            color: #fff;
        }

        &:hover,
        &:active {
            color: #fff;
        }
    }

    svg {
        fill: #ccc;
    }

    &:hover svg {
        fill: #fff;
    }
}
