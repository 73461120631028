.contact-content {
    max-width: 872px;
    margin: 0 auto;

    .contact__description {
        margin-top: 50px;
        text-align: justify;
        margin-left: 10px;
        @include calc-font(16, 24);

        @include media-sp {
            margin-top: calcP(40);
            @include calc-fontvw(16, 24);
        }

        ul {
            list-style-type: "●";
            padding-left: 5px;
        }

        li {
            padding-left: 5px;
            margin-bottom: 15px;
        }

        &-notice {
            margin-left: -10px;
        }
    }
}

.support-content-tab {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: start;

    @include media-sp {
        margin-top: calcP(50);
    }

    li {
        margin-right: 80px;
        text-align: center;

        @include media-sp {
            margin-right: 7.46268656716418%;
            width: 28.35820895522388%;
        }

        &.last {
            margin-right: 0;
        }

        .support-content-tab__caution {
            filter: filterVal2();
            color: #acacac;
            margin-top: 10px;

            @include calc-font(14, 16);

            font-weight: var(--font-weight-regular);

            @include media-sp {
                @include calc-fontvw(12, 14);
            }
        }

        &.center {
            .support-content-tab__caution {
                @include media-sp {
                    margin-top: calcP(7);
                }
            }
        }

        &.first {
            .support-content-tab__caution {
                @include media-sp {
                    margin-top: calcP(30);
                }
            }
        }

        .support-content-tab-button {
            display: block;
            padding-bottom: 8px;
            font-weight: 500;
            filter: filterVal2();

            &:hover {
                text-decoration: none;
            }

            @include media-sp {
                width: 100%;
            }

            img {
                max-width: 80px;

                @include media-sp {
                    max-width: initial;
                    width: 71.57894736842105%;
                }
            }

            .txt {
                filter: filterVal2();
                color: #acacac;
                padding-top: 10px;
                display: inline-block;
                @include calc-font(16, 19);

                font-weight: var(--font-weight-bold);

                @include media-sp {
                    padding-top: 5px;
                    min-height: 2em;
                    @include calc-fontvw(14, 16.8);
                }
            }

            &__contact {
                &.active {
                    filter: none;
                    color: #000;
                    border-bottom: 2px solid #000;
                }
            }

            @include media-pc {
                &:hover {
                    filter: none;
                    color: #000;
                }
            }
        }
    }
}
@include media-sp {
    .support-content-tab {
        /* Allow items to wrap on smaller screens */
        flex-wrap: wrap;
        justify-content: center;
    }
    
    .support-content-tab li {
        width: 30%;
        margin-right: 0; // Adjust spacing if needed
        margin-bottom: 20px; // Some gap between rows
    }
}

.support-content {
    display: none;

    &.active {
        display: block;
    }

    &-wrapper {
        padding-top: 50px;

        @include media-sp {
            padding-top: calcP(50);
        }

        .modal-new-address-form-parts.half {
            width: 48.90929965556831%;

            @include media-sp {
                width: 100%;
            }
        }

        .button-attache {
            min-width: 150px;

            @include media-sp {
                @include calc-fontvw(14, 14);
            }
        }

        .attached-file-txt {
            margin-left: 15px;
            display: inline-block;
            @include calc-font(16, 16);

            @include media-sp {
                @include calc-fontvw(16, 16);
            }
        }

        .support__input-file {
            display: none;
        }

        .button-delete {
            min-width: 50px;
            margin-left: 15px;

            @include media-sp {
                @include calc-fontvw(14, 14);
            }
        }

        .attached-file-txt-append {
            display: inline-block;
            @include calc-font(16, 16);

            @include media-sp {
                @include calc-fontvw(16, 16);
            }
        }

        .button-support-submit, .button-collaboration-submit {
            display: block;
            margin: 40px auto 0;
            width: 372px;
            height: 55px;

            @include media-sp {
                margin-top: calcP(40);
                padding: 18px 0;
                width: 100%;
                height: auto;
            }
        }
    }
}

.support-attached-file__description {
    margin-top: 20px;
    @include calc-font(16, 24);

    @include media-sp {
        margin-top: calcP(20);
        @include calc-fontvw(16, 24);
    }
}

.support-attached-file__description-pic {
    margin-top: 20px;

    @include media-sp {
        margin-top: calcP(20);
    }
}

.distributors {
    color: #0000EE;
}
