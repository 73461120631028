.cart__product-list--mydashboard {
    .cart__product-list-article-pic {
        @include media-pc {
            width: 100px;
            height: 100px;
            min-width: 100px;
        }
    }
    @include media-pc {
        .cart__product-list-article-detail-wrapper {
            flex-basis: 567px;
        }

        .cart__product-list-article-form {
            flex-basis: 70px;
        }

        .cart__product-list-article-total-price {
            flex-basis: 230px;
        }
    }
}

.mydashboard__button {
    display: block;
    margin: 40px auto 0;
    min-width: 250px;
    text-align: center;

    @include media-sp {
        margin-top: calcP(30);
        width: 100%;
    }
}

.cart__ttl--dashbord-margin {
    margin-top: 80px;
    @include media-sp {
        margin-top: calcP(70);
    }
}

.no-order {
    &-wrapper {
        padding: 10px;
        display: flex;
        align-items: center;
        font-size: 1.2rem;

        @include media-sp {
            position: relative;
            margin-top: calcP(30);
            padding: 15px calcP(15);
            flex-wrap: wrap;
        }
    }
}

.mydashboard-recent {
    &-wrapper {
        margin-top: 40px;
        padding: 13px 15px;
        display: flex;
        align-items: center;
        background-color: #f1f1f1;

        @include media-sp {
            position: relative;
            margin-top: calcP(30);
            padding: 15px calcP(15);
            flex-wrap: wrap;
        }
    }

    &-txt {
        margin-right: 30px;

        @include media-sp {
            order: 2;
        }

        &__ttl {
            @include calc-font(12, 12);

            @include media-sp {
                @include calc-fontvw(12, 12);
            }
        }

        &__date {
            margin-top: 7px;
            font-weight: 500;

            @include media-sp {
                margin-top: calcP(10);
                @include calc-fontvw(16, 16);
            }
        }
    }

    &__label {
        @include media-pc {
            min-width: 91px;
        }
        @include media-sp {
            width: 100%;
            order: 1;
        }
    }

    &__label-text {
        @include calc-font(14, 16.8);
        max-width: 260px;
        margin-left: 10px;
        color: #264064;

        @include media-sp {
            width: 100%;
            margin-top: 10px;
            margin-bottom: 15px;
            margin-left: 0;
            max-width: 100%;
            order: 2;
            @include calc-fontvw(14, 16.8);
        }

        &__shipped {
            color: #748B9F;
        }

        &__error ,
        &__hold {
            color: #FF006C;
        }

        &__canceled {
            color: #ACACAC;
        }
    }

    &__button {
        display: flex;
        flex: 1;
        justify-content: flex-end;

        @include media-sp {
            position: absolute;
            display: block;
            top: 15px;
            right: calcP(15);
            @include calc-fontvw(14, 14);
        }
    }
}

.cart__product-list--mydashboard {
    padding-top: 0;
}

.mydashboard-account {
    &-wrapper {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;

        @include media-sp {
            display: block;
            margin-top: 0;
        }
    }

    &-content {
        flex-basis: 400px;

        @include media-sp {
            margin-top: calcP(30);
            width: 100%;
            flex-basis: auto;
        }

        &__ttl {
            margin-bottom: 20px;
            @include calc-font(18, 18);

            font-weight: 500;
            color: #000;

            @include media-sp {
                margin-bottom: calcP(20);
                @include calc-fontvw(18, 18);
            }
        }

        &__detail {
            margin-bottom: 20px;
            @include calc-font(16, 24);

            @include media-sp {
                margin-bottom: calcP(12);
                @include calc-fontvw(14, 21);
            }

            .name {
                font-weight: 500;

                @include media-sp {
                    display: inline-block;
                    padding-bottom: 5px;
                    @include calc-fontvw(16, 21);
                }
            }
        }
    }
}

.mydashboard-point {
    &-wrapper {
        margin-top: 40px;
        display: flex;

        @include media-sp {
            margin-top: calcP(30);
            display: block;
        }
    }

    &-content {
        display: flex;
        padding: 20px;
        width: 50%;
        align-items: center;
        flex-wrap: wrap;
        box-sizing: border-box;
        border: 1px solid #e8e8e8;

        @include media-sp {
            display: block;
            width: 100%;
            padding: calcP(20);
        }

        &.first {
            border-right: none;

            @include media-sp {
                border-right: 1px solid #e8e8e8;
                border-bottom: none;
            }
        }

        &__ttl {
            margin-bottom: 10px;
            @include calc-font(20, 20);

            font-weight: 500;
            width: 100%;

            @include media-sp {
                margin-bottom: calcP(8);
                @include calc-fontvw(20, 24);
            }
        }

        &__description-point {
            .txt {
                margin-right: 20px;
                display: inline-block;
                @include calc-font(14, 14);

                @include media-sp {
                    margin-right: calcP(20);
                    @include calc-fontvw(14, 14);
                }
            }

            img {
                margin-right: 12px;
                display: inline-block;
                vertical-align: middle;

                @include media-sp {
                    margin-right: calcP(12);
                }
            }
        }
    }
}

.mydashboard-coupon__list {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media-sp {
        margin-top: 0;
        display: block;
    }

    &-article {
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 20px;
        width: calcP(170);

        @include media-sp {
            margin-top: calcP(30);
            margin-right: 0 !important;
            margin-left: 0 !important;
            width: 100% !important;
        }

        &:last-child {
            margin-right: 0;
        }

        &-pic {
            img {
                @include media-sp {
                    width: 100%;
                }
            }
        }

        &-ttl {
            margin-top: 20px;
            @include calc-font(18, 18);

            font-weight: 700;
            color: #000;

            @include media-sp {
                margin-top: calcP(20);
                @include calc-fontvw(18, 18);
            }
        }

        &-description {
            margin-top: 10px;
            @include calc-font(16, 24);

            @include media-sp {
                margin-top: calcP(10);
                @include calc-fontvw(16, 24);
            }
        }
    }
}

.mydashboard-support__list {
    display: flex;
    justify-content: space-between;
    margin-top: 44px;

    @include media-sp {
        margin-top: calcP(34);
        flex-wrap: wrap;
        justify-content: center;
    }

    &-article {
        width: 24.5%;
        height: 164px;
        text-align: center;
        box-sizing: border-box;

        .mydashboard-support__list__text {
            color: #707070;
            margin-top: 10px;
            @include calc-font(14, 16);
        }

        @include media-sp {
            width: 30%;
            height: auto;
            margin-bottom: 20px;
            margin-right: 10px;
        }

        > a {
            border: 1px solid #ddd;
            border-radius: 6px;
            display: block;
            padding: 25px 0 30px;
            cursor: pointer;

            @include media-pc {
                transition: 0.3s;

                &:hover {
                    .mydashboard-support__list-article-pic {
                        img {
                            filter: none;
                            opacity: 1;
                        }
                    }

                    text-decoration: none;
                    background-color: #f1f1f1;
                    border: 1px solid #f1f1f1;
                }
            }

            @include media-sp {
                padding-top: calcP(10);
                padding-bottom: calcP(24);
                height: 100%;
            }
        }

        &-ttl {
            margin-top: 10px;
            font-weight: 500;

            @include media-sp {
                margin-top: calcP(5);
                @include calc-fontvw(14, 16.8);
            }
        }

        &-pic {
            img {
                filter: filterVal();
                width: 80px;
                height: 80px;

                @include media-sp {
                    width: 60%;
                }
            }
        }
    }
}
