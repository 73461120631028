.terms-of-service-row-wrapper {
    display: flex;

    @include media-sp {
        flex-direction: column;
        .myaccount__nav-list {
            margin-top: calcP(40);
        }
    }
}

.terms-of-service-row-nav {
    width: 304px;
    order: 1;

    @include media-sp {
        width: 100%;
    }
}

.terms-of-service-row-content {
    position: relative;
    margin-left: 65px;
    flex: 1;
    order: 2;

    @include media-sp {
        margin-left: 0;
        margin-top: calcP(40);
    }

    &__onecolumnformat {
        max-width: 872px;
        margin-left: 0;
        margin: 0 auto;

        .terms-of-service-parts__full-text {
            margin-top: 40px;
            font-weight: var(--font-weight-normal);
            @include calc-font(16, 24);

            @include media-sp {
                @include calc-fontvw(16, 24);
                margin-top: calcP(40);
            }
        }

        .terms-of-service-parts__full-img {
            margin-top: 40px;

            @include media-sp {
                margin-top: calcP(40);
            }
        }
    }

    .terms-of-service-parts__ttl-text {
        margin-top: 50px;
        
        @include media-sp {
            margin-top: calcP(40);
        }

        &__ttl {
            color: #000000;
            font-weight: var(--font-weight-bold);
            @include calc-font(18, 24);
        
            @include media-sp {
                @include calc-fontvw(18, 24);
            }
        }

        &__text {
            color: #666666;
            font-weight: var(--font-weight-regular);
            @include calc-font(16, 24);
        
            @include media-sp {
                @include calc-fontvw(16, 24);
            }
        }
    }

    .terms-of-service-parts__ttl-list {
        margin-top: 50px;
        
        @include media-sp {
            margin-top: calcP(70);
        }

        &__ttl {
            border-bottom: 2px solid #E8E8E8;
            color: #000000;
            font-weight: var(--font-weight-bold);
            @include calc-font(24, 28);
            padding-bottom: 10px;
        
            @include media-sp {
                @include calc-fontvw(22, 26.4);
            }
        }

        &__numlist {
            margin-top: 40px;
        
            @include media-sp {
                margin-top: calcP(30);
            }

            > li {
                display: table;
                margin-top: 15px;
                &:first-child {
                    margin-top: 0;
                }

                > div {               
                    color: #666666;
                    display: table-cell;     
                    @include calc-font(16, 24);
        
                    @include media-sp {
                        @include calc-fontvw(16, 24);
                    }

                    a {
                        text-decoration: underline;
                    }

                    &:first-child {
                        width: 20px;
                    }
                }
            }
        }

        &__text {
            margin-top: 25px;
        }
    }

    .terms-of-service-parts__dotlist {
        margin-top: 40px;
    
        @include media-sp {
            margin-top: calcP(30);
        }
        li {
            display: table;
            margin-top: 15px;

            > div {
                display: table-cell;             
                color: #666666;
                display: table-cell;     
                @include calc-font(16, 24);
        
                @include media-sp {
                    @include calc-fontvw(16, 24);
                }

                &:first-child {
                    width: 19px;
                    @include calc-font(6, 6);
                }
            }
        }
    }
}
