.p1s-container-outer{width:100%;}
.p1s-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 870px;
    margin: 5px auto 10px auto;
}

.p1s-campaign-image{
    width: 100%;
}

.p1s-campaign-details {
    width: 45%;
    padding: 15px 40px;
    box-sizing: border-box;
}

.p1s-campaign-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.p1s-campaign-text {
    font-size: 15px;
    margin-bottom: 20px;
}

.button-cg-fontsize {
   font-size: large!important;
   margin-top: 15px;
}

.p1s-campaign-date {
    font-size: 13px;
    margin-bottom: 5px;
    .time-container2 > span {
        color: #ff006c !important;
        font-size: inherit !important;
    }
}

@media (max-width: 750px) {
    .p1s-container {
        flex-direction: column;
    }

    .p1s-container {
        width: 100%;
    }

    .p1s-campaign-image-container {
        width: 95%;
        text-align: center;
    }
}

@media (min-width: 751px) {
    .p1s-campaign-image-container {
        width: 55%;
    }

    .p1s-hr{width:max(84%, 870px);}
}

.p1s-entry-wrap {
    text-align: center;
    button.button-basic {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.p1s-entry-button {
    -webkit-transition: all 500ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
    -moz-transition: all 500ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
    -ms-transition: all 500ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
    -o-transition: all 500ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
    transition: all 500ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
    display: block;
    margin: 20px auto 0px auto;
    text-decoration: none;
    border-radius: 4px;
    padding: 10px 20px;
}

.p1s-entry-button {
    max-width: 55%;
    color: rgba(30, 22, 54, 0.6);
    box-shadow: rgba(30, 22, 54, 0.4) 0 0px 0px 2px inset;
}

.p1s-entry-button:hover {
    color: rgba(255, 255, 255, 0.85);
    box-shadow: rgba(30, 22, 54, 0.8) 0 0px 0px 40px inset;
}

.p1s-campaign-banner-footer {
    position: fixed;
    bottom: 25px;
    left: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    opacity: 0;
    z-index: 8;
    transition: opacity 0.3s ease, background-color 0.3s ease;
  }

@media screen and (max-width: 991px) {
    .p1s-campaign-banner-footer {
        bottom: 25px;
        left: 25px;
        width: 9.6vw;
        height: 9.6vw;
      }
}

@media screen and (max-width: 750px) and (min-width: 451px) {
    .p1s-campaign-image {
      width: 80%;
      padding: 15px;
    }
    .p1s-campaign-details {
      width: 90%;
      padding: 15px;
    }
  }
  @media (max-width: 450px) {
    .p1s-campaign-image {
      width: 100%;
    }
    .p1s-campaign-details {
      width: 100%;
      padding: 7px;
    }
  }