.server-maintenance {
    &-content {
        padding-top: 150px;

        @include media-sp {
            padding-top: calcP(70);
        }

        &__img {
            text-align: center;
        }

        &__ttl {
            color: #000;
            text-align: center;
            @include calc-font(36, 42);

            font-weight: var(--font-weight-bold);
            margin-top: 40px;

            @include media-sp {
                @include calc-fontvw(32, 38);

                margin-top: calcP(40);
            }
        }

        &__text {
            max-width: 560px;
            margin: 40px auto 0;
            @include calc-font(16, 24);

            font-weight: var(--font-weight-regular);

            @include media-sp {
                @include calc-fontvw(16, 24);
            }
        }
    }
}
