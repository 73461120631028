.reset-password-content {
    &-wrapper {
        margin: 76px auto 0;
        max-width: 400px;
        box-sizing: border-box;

        @include media-sp {
            margin-top: calcP(44);
        }

        .reset-password-content__text {
            margin: 40px 0;
            @include calc-font(16, 24);

            font-weight: var(--font-weight-regular);

            @include media-sp {
                margin-top: calcP(40);
                @include calc-fontvw(16, 24);
            }
        }

        .reset-password-form__ttl {
            margin-bottom: 10px;
            @include calc-font(14, 16);

            font-weight: var(--font-weight-regular);

            @include media-sp {
                @include calc-fontvw(14, 16);
            }
        }

        input.input__text {
            width: 100%;
        }

        button.button-basic {
            margin-top: 30px;
            width: 100%;

            @include media-sp {
                margin-top: calcP(30);
                width: 100%;
            }
        }

        button.button-back {
            margin-top: 20px;
            width: 100%;
            padding: 12px 0;
            text-align: center;
            min-width: 230px;
            @include calc-font(14, 16);

            font-weight: 500;
            background-color: #f1f1f1;
            border-radius: 6px;
            @extend %img-filter;

            @include media-pc {
                &:not(:disabled):hover {
                    background-color: #ddd;
                    color: #000;
                }
            }

            @include media-sp {
                min-width: initial;
                padding-left: 54px;
                padding-right: 54px;
                box-sizing: border-box;
                @include calc-fontvw(14, 16);

                margin-top: calcP(20);
            }
        }

        &__sent-email {
            margin: 76px auto 0;
            max-width: 560px;
            box-sizing: border-box;

            @include media-sp {
                margin-top: calcP(44);
            }

            .reset-password--sent-email-content__text {
                margin-top: 20px;
                @include calc-font(16, 24);

                font-weight: var(--font-weight-regular);

                @include media-sp {
                    margin-top: calcP(20);
                    @include calc-fontvw(16, 24);
                }
            }

            button.button-basic {
                margin: 40px auto 0;
                width: 100%;
                max-width: 400px;
                display: block;

                @include media-sp {
                    margin-top: calcP(20);
                    width: 100%;
                }
            }

            .main-content__ttl {
                margin-top: 40px;

                @include media-sp {
                    margin-top: calcP(30);
                }
            }
        }
    }
}
