@media screen and (min-width:500px) {
  #countdown {
    width: 465px;
    height: 112px;
  }

  #countdown #tiles>span {
    width: 92px;
    max-width: 92px;
    margin: 0 7px;
  }

  #countdown .labels li {
    width: 107px;
    font: bold 15px 'Droid Sans', Arial, sans-serif;
    color: #f47321;
    text-shadow: 1px 1px 0px #000;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
  }
}

@media screen and (max-width:499px) {
  #countdown {
    width: 90vw;
    height: 112px;
  }

  #countdown #tiles>span {
    width: 19vw;
    max-width: 19vw;
    margin: 0 0.5vw;
  }

  #countdown .labels li {
    width: 20vw;
    font: bold 12px 'Droid Sans', Arial, sans-serif;
    color: #f47321;
    text-shadow: 1px 1px 0px #000;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
  }
}

#countdown {
  text-align: center;
  background: #222;
  background-image: -webkit-linear-gradient(top, #222, #333, #333, #222);
  background-image: -moz-linear-gradient(top, #222, #333, #333, #222);
  background-image: -ms-linear-gradient(top, #222, #333, #333, #222);
  background-image: -o-linear-gradient(top, #222, #333, #333, #222);
  border: 1px solid #111;
  border-radius: 5px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
  margin: auto;
  padding: 24px 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#countdown #tiles {
  position: relative;
  z-index: 1;
}

#countdown #tiles>span {
  font: bold 48px 'Droid Sans', Arial, sans-serif;
  text-align: center;
  color: #111;
  background-color: #ddd;
  background-image: -webkit-linear-gradient(top, #bbb, #eee);
  background-image: -moz-linear-gradient(top, #bbb, #eee);
  background-image: -ms-linear-gradient(top, #bbb, #eee);
  background-image: -o-linear-gradient(top, #bbb, #eee);
  border-top: 1px solid #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.7);
  padding: 18px 0;
  display: inline-block;
  position: relative;
}

#countdown .labels {
  width: 100%;
  height: 25px;
  text-align: center;
}