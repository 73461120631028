.news-content {
    &-wrapper {
        @include media-sp {
            margin-top: calcP(0);
        }
    }

    .news-content-ttl-search {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media-sp {
            flex-direction: column;
            justify-content: start;
            align-items: start;

            .input__search-form {
                margin-top: calcP(40);
                width: 100%;

                .input__search {
                    width: 100%;
                }
            }
        }
    }

    .news-content-search-results__text {
        color: #000;
        margin-top: 30px;
        @include calc-font(18, 11);

        font-weight: var(--font-weight-medium);

        @include media-sp {
            @include calc-fontvw(18, 21);

            margin-top: calcP(30);
        }
    }
    .news-content-search-results__tag {
        .tag-list {
            display: flex;
            justify-content: start;
            align-items: start;
            flex-wrap: wrap;
            margin-top: 20px;

            > li {
                margin-bottom: 10px;
                margin-right: 10px;

                a {
                    background: #acacac;
                    color: #fff;
                    @include calc-font(14, 24);

                    font-weight: var(--font-weight-regular);
                    padding: 5px 7px;
                    transition: 0.3s;

                    &:hover {
                        opacity: 0.7;
                        text-decoration: none;
                    }

                    &.promo {
                        background: #748b9f;
                    }

                    &.bonus {
                        background: #8a9579;
                    }
                }
            }
        }
    }

    .news-content-list {
        display: flex;
        justify-content: start;
        align-items: stretch;
        flex-wrap: wrap;
        margin-top: 50px;

        img {
            width: 100%;
        }

        @include media-sp {
            margin-top: calcP(30);
        }

        > li {
            background: #f1f1f1;
            margin-right: calc((20 / 1240) * 100%);
            margin-bottom: 40px;
            width: calc((400 / 1240) * 100%);

            @include media-sp {
                margin-right: calc((7 / 375) * 100%);
                margin-bottom: 20px;
                width: 49%;

                &:nth-child(2n) {
                    margin-right: 0;
                }
            }

            @include media-pc {
                &:nth-child(3n) {
                    margin-right: 0;
                }
            }

            img {
                aspect-ratio: 5 / 6;
            }

            .news-content-list-ttl {
                @include media-sp {
                    @include calc-fontvw(14, 16.8);
                }

                a {
                    color: #000;
                    @include calc-font(18, 24);

                    font-weight: var(--font-weight-bold);

                    @include media-sp {
                        @include calc-fontvw(14, 16.8);
                    }
                }
            }

            .news-content-list-details {
                padding: 20px;

                @include media-sp {
                    padding: 10px;
                }
            }

            .news-content-list-date-views {
                color: #666;
                display: flex;
                justify-content: start;
                align-items: center;
                @include calc-font(16, 24);

                font-weight: var(--font-weight-regular);
                margin-top: 10px;

                @include media-sp {
                    @include calc-fontvw(12, 24);

                    margin-top: calcP(7);
                }

                .news-content-list-views {
                    margin-left: 20px;

                    @include media-sp {
                        margin-left: 10px;
                    }
                }
            }

            .news-content-list-content {
                @include calc-font(16, 24);

                font-weight: var(--font-weight-regular);

                @include media-sp {
                    display: none;
                }
            }

            .tag-list {
                display: flex;
                justify-content: start;
                align-items: start;
                flex-wrap: wrap;
                margin-top: 20px;

                @include media-sp {
                    display: none;
                }

                > li {
                    margin-bottom: 10px;
                    margin-right: 10px;

                    a {
                        background: #acacac;
                        color: #fff;
                        @include calc-font(14, 24);

                        font-weight: var(--font-weight-regular);
                        padding: 5px 7px;
                        transition: 0.3s;

                        &:hover {
                            opacity: 0.7;
                            text-decoration: none;
                        }

                        &.promo {
                            background: #748b9f;
                        }

                        &.bonus {
                            background: #8a9579;
                        }
                    }
                }
            }
        }
    }

    .not-content {
        text-align: center;
        color: #ccc;
        margin-top: 60px;
        font-size: 1.75rem;
        line-height: 1.1785714286;
        font-weight: var(--font-weight-bold);
    }

    &-details-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: start;

        @include media-sp {
            flex-direction: column;
        }

        .news-row-content {
            width: 100%;
            max-width: 872px;
            margin-right: calc(((1240 - 872 - 304) / 1240) * 100%);

            .tag-list {
                margin-top: 40px;
            }
        }

        .news-row-side {
            max-width: 304px;

            @include media-sp {
                margin-top: calcP(85);
                max-width: 100%;

                .input__search {
                    width: 100%;
                }
            }

            &__ttl {
                border-bottom: 2px solid #e8e8e8;
                color: #000;
                @include calc-font(24, 28);

                font-weight: var(--font-weight-bold);
                padding-bottom: 10px;

                @include media-sp {
                    @include calc-fontvw(22, 26.4);
                }
            }

            &-posts,
            &-tags {
                margin-top: 50px;

                @include media-sp {
                    margin-top: calcP(40);
                }
            }

            &-tags {
                @include media-sp {
                    margin-top: calcP(70);
                }
            }

            &-archive {
                margin-top: 40px;

                @include media-sp {
                    margin-top: calcP(30);
                }

                > li {
                    margin-bottom: 20px;
                    margin-top: calcP(20);

                    &:last-child {
                        margin-bottom: 0;
                    }

                    a {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        img {
                            object-fit: cover;
                            height: 50px;
                            width: 50px;
                        }

                        p {
                            color: #666;
                            @include calc-font(16, 19.2);

                            font-weight: var(--font-weight-regular);
                            width: 80%;

                            @include media-sp {
                                @include calc-fontvw(16, 19.2);

                                width: 82%;
                            }
                        }
                    }
                }
            }
        }

        .tag-list {
            display: flex;
            justify-content: start;
            align-items: start;
            flex-wrap: wrap;
            margin-top: 20px;

            > li {
                margin-bottom: 10px;
                margin-right: 10px;

                a {
                    background: #acacac;
                    color: #fff;
                    @include calc-font(14, 24);

                    font-weight: var(--font-weight-regular);
                    padding: 5px 7px;
                    transition: 0.3s;

                    &:hover {
                        opacity: 0.7;
                        text-decoration: none;
                    }

                    &.promo {
                        background: #748b9f;
                    }

                    &.bonus {
                        background: #8a9579;
                    }
                }
            }
        }

        .social-list {
            margin-top: 50px;
            display: flex;
            justify-content: start;
            align-items: center;

            @include media-sp {
                margin-top: calcP(50);
                flex-direction: column;
                align-items: start;
            }

            li {
                margin-left: 20px;

                @include media-sp {
                    margin-left: 0;

                    &:last-child {
                        margin-top: calcP(10);
                    }
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    &-details {
        .main-content__ttl {
            @include calc-font(28, 33.6);

            font-weight: var(--font-weight-bold);

            @include media-sp {
                @include calc-fontvw(22, 25);

                margin-top: calcP(0);
            }
        }

        .news-content-details__date-views {
            margin-top: 10px;
            color: #000;
            display: flex;
            justify-content: start;
            align-items: center;
            @include calc-font(18, 21);

            font-weight: var(--font-weight-regular);

            .news-content-details__views {
                margin-left: 20px;
            }

            @include media-sp {
                @include calc-fontvw(18, 21);

                margin-top: calcP(15);
            }
        }

        .news-content-details-parts {
            margin-top: 50px;

            @include media-sp {
                margin-top: calcP(40);
            }

            &-text {
                color: #666;
                @include calc-font(16, 24);

                font-weight: var(--font-weight-regular);
                margin-top: 20px;

                &:first-child {
                    margin-top: 0;
                }

                @include media-sp {
                    @include calc-fontvw(16, 24);

                    margin-top: calcP(20);
                }
            }

            &-images {
                margin-top: 80px;

                @include media-sp {
                    margin-top: calcP(70);
                }

                &__ttl {
                    color: #000;
                    @include calc-font(18, 24);

                    font-weight: var(--font-weight-bold);

                    @include media-sp {
                        @include calc-fontvw(18, 24);
                    }
                }

                &__img {
                    margin-top: 20px;
                }
            }

            &-caution {
                margin-top: 80px;
                color: #666;
                @include calc-font(16, 24);

                font-weight: var(--font-weight-regular);

                @include media-sp {
                    @include calc-fontvw(16, 24);

                    margin-top: calcP(40);
                }
            }
        }
    }
}
