.myaccount-paypal-content {
    margin: 70px auto 0;
    max-width: 560px;
    text-align: center;

    @include media-sp {
        margin-top: calcP(50);
        max-width: initial;
    }

    &__pic {
        img {
            @include media-sp {
                width: 60.8955%;
            }
        }
    }

    &__description {
        margin-top: 30px;
        text-align: left;
        @include calc-font(16, 24);

        @include media-sp {
            margin-top: calcP(30);
            @include calc-fontvw(16, 24);
        }
    }

    &__button {
        margin-top: 40px;

        @include media-sp {
            margin-top: calcP(40);

            .button-basic {
                width: 68.6567%;
            }
        }
    }

    &__notice {
        margin-top: 20px;
        @include calc-font(14, 14);

        @include media-sp {
            margin-top: calcP(20);
            @include calc-fontvw(14, 19);
        }
    }

    &__date {
        margin-top: 10px;
        @include calc-font(14, 14);

        @include media-sp {
            @include calc-fontvw(14, 14);
        }
    }
}
