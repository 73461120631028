.jsUnderLine {
    position: relative;

    .underline {
        position: absolute;
        bottom: 0;
        height: 2px;
        background-color: #000;
        transition: all 0.3s ease;

        &.notransition {
            transition: none;
        }
    }
}
