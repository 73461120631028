.cart__ttl {
    margin-top: 50px;
    @include calc-font(24, 32);

    padding-bottom: 12px;
    color: #000;
    border-bottom: 2px solid #e8e8e8;

    @include media-sp {
        margin-top: calcP(45);
        @include calc-fontvw(22, 29);

        padding-bottom: calcP(17);
    }

    &--recommend {
        margin-top: 95px;

        @include media-sp {
            margin-top: 70px;
        }
    }

    .mypoints__description__title {
        color: #666;
        text-align: right;
        font-weight: 400;
        @include calc-font(14, 21);

        @include media-sp {
            @include calc-fontvw(14, 21);
        }
    }

    &.buynow_header {
        display: flex;
        @include media-sp {
            display: inline-block;
        }

        .m-auto {
            margin: auto;
        }

        .red-notice {
            color: #fe3f46;
            @include media-sp {
                display: flex;
            }
        }
    }
}

.cart__noitem {
    margin-top: 50px;
    @include calc-font(24, 32);

    padding-bottom: 12px;
    color: #000;

    @include media-sp {
        margin-top: calcP(45);
        @include calc-fontvw(22, 29);

        padding-bottom: calcP(17);
    }
}

.cart__product-list {
    padding: 10px 0 0 20px;

    @include media-sp {
        padding: calcP(10) 0;
    }

    &-article {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px solid #e8e8e8;

        @include media-sp {
            display: block;
            padding: calcP(20) 0;
        }

        &-pic {
            display: flex;
            width: 150px;
            height: 150px;
            @include media-pc {
                min-width: 150px;
            }

            @include media-sp {
                position: relative;
                padding-top: 24%;
                width: 24%;
                height: auto;

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                }
            }
        }

        &-detail {
            &-wrapper {
                display: flex;
                align-items: center;
                flex-basis: 435px;

                @include media-sp {
                    flex-basis: 100%;
                    align-items: flex-start;
                }
            }

            margin-left: 30px;
            @include calc-font(14, 20);

            @include media-sp {
                margin-left: 4%;
                width: 72%;
                @include calc-fontvw(14, 20);
            }

            &-work,
            &-ttl {
                color: #000;
                font-weight: 500;
            }

            &-ttl {
                @include calc-font(16, 19.2);

                @include media-sp {
                    @include calc-fontvw(16, 19.2);
                }
            }

            &-code {
                @include media-sp {
                    @include calc-fontvw(14, 20);
                }
            }

            &-price {
                margin-top: 12px;
                color: #000;

                label {
                    margin-left: 10px;
                }
                @include media-sp {
                    margin-top: calcP(12);
                }
            }

            &-status {
                margin-bottom: 10px;
                @include calc-font(14, 14);

                @include media-sp {
                    margin-bottom: calcP(10);
                    @include calc-fontvw(14, 14);
                }

                &--pink {
                    color: #ff006c;
                }

                .status-label {
                    margin-right: 10px;
                    display: inline-block;
                    vertical-align: middle;

                    @include media-sp {
                        margin-right: 0;
                        margin-bottom: 5px;
                    }
                }
            }
        }

        &-form {
            flex-basis: 240px;

            .custom-select {
                justify-content: flex-end;
            }

            @include media-sp {
                margin-top: 26px;
                display: inline-block;
                padding-left: 28%;
                text-align: left;

                @include media-sp {
                    margin-top: calcP(26);
                }

                .custom-select {
                    justify-content: flex-start;
                }
            }
        }

        &-total-price {
            flex-basis: 176px;
            text-align: right;
            color: #000;

            @include media-sp {
                display: inline-block;
                margin-left: 20px;
                flex-basis: initial;
                text-align: left;
            }
        }

        &-checkout {
            font-weight: 500;
            flex-basis: 369px;
            text-align: right;

            @include media-sp {
                padding-left: 28%;
                flex-basis: initial;
                width: 100%;
                box-sizing: border-box;
                text-align: left;

                .button-basic {
                    margin-top: 20px;
                    width: 100%;
                }
            }

            &-action {
                margin-top: 20px;
                margin-left: auto;
                width: 230px;
                display: flex;
                justify-content: flex-end;
                @include calc-font(14, 14);

                button:first-child {
                    margin-right: 15px;
                }

                button:last-child {
                    margin-right: 0;
                }

                @include media-sp {
                    margin-top: calcP(20);
                    width: 100%;
                    box-sizing: border-box;
                    @include calc-fontvw(12, 12);

                    .button-text {
                        @include calc-fontvw(12, 12);
                    }
                }

                .button-favorite {
                    background: #f1f1f1;
                    border-radius: 50%;
                    position: relative;
                    height: 36px;
                    width: 36px;

                    @include media-sp {
                        height: 30px;
                        width: 30px;
                    }

                    img {
                        position: absolute;
                        top: 29%;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        transition: 0.3s;
                        @include media-sp {
                            width: 17px;
                            height: 14px;
                        }
                    }

                    @include media-pc {
                        &:hover {
                            img {
                                transition: 0.3s;
                                filter: invert(66%) sepia(1%) saturate(0%) hue-rotate(172deg) brightness(104%) contrast(93%);
                            }
                        }
                    }

                    &.is-active {
                        img {
                            filter: invert(16%) sepia(91%) saturate(6783%) hue-rotate(328deg) brightness(99%) contrast(105%);
                        }
                    }
                }
            }
        }
    }
}

.cart__total-price {
    &-wrapper {
        margin: 40px 0 80px auto;
        padding: 20px 0;
        max-width: 1220px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f1f1f1;

        @include media-sp {
            margin: calcP(30) 0 calcP(75) -20px;
            padding: calcP(20) 0;
            width: calc(100% + 40px);
            display: block;

            .button-basic {
                display: block;
                width: 68.8%;
                margin-right: auto;
                margin-left: auto;
            }
        }
    }

    margin-right: 40px;
    @include calc-font(22, 22);

    font-weight: 500;
    color: #000;

    @include media-sp {
        margin-right: 0;
        margin-bottom: calcP(15);
        text-align: center;
        @include calc-fontvw(18, 18);
    }
}

.cart__recommend-list {
    display: flex;

    @include media-sp {
        padding: 0;
        flex-wrap: wrap;
    }

    &-article {
        margin: 40px 20px 0 0;
        text-align: center;

        @include media-sp {
            margin-top: calcP(30);
            margin-right: 2.089552238%;
            width: calc(( 164 / 335 ) * 100%);

            &:nth-child(2n) {
                margin-right: 0;
            }

            &:nth-child(n + 3) {
                margin-top: calcP(15);
            }
        }

        &:last-child {
            margin-right: 0;
        }

        > a {
            display: block;
        }

        &-ttl {
            margin-top: 10px;
            font-weight: 500;
            color: #000;
            @include calc-font(16, 19.2);

            @include media-sp {
                margin-top: calcP(10);
                @include calc-fontvw(14, 17);
            }
        }
    }
}

.modal-paypal {
    &-wrapper {
        padding: 36px 0;
        width: 496px;
        text-align: center;
        box-sizing: border-box;

        @include media-sp {
            padding: calcP(40) calcP(20);
            width: auto;
        }

        .modal-paypal-setup-button {
            margin-top: 40px;

            @include media-sp {
                margin-top: calcP(40);

                .button-basic {
                    width: 100%;
                }
            }
        }
    }

    &__ttl {
        @include calc-font(32, 32);

        font-weight: 900;
        color: #000;

        @include media-sp {
            @include calc-fontvw(28, 35);
        }
    }

    &__description {
        margin-top: 30px;
        @include calc-font(16, 24);

        @include media-sp {
            margin-top: calcP(30);
            @include calc-fontvw(16, 24);
        }
    }
}

.modal-order {
    &-wrapper {
        padding: 36px 0;
        width: 496px;
        text-align: center;
        box-sizing: border-box;

        @include media-sp {
            padding: calcP(40) calcP(20);
            width: auto;
        }

        .modal-order-setup-button {
            margin-top: 40px;

            @include media-sp {
                margin-top: calcP(40);

                .button-basic {
                    width: 100%;
                }
            }
        }
    }

    &__ttl {
        @include calc-font(32, 32);

        font-weight: 900;
        color: #000;

        @include media-sp {
            @include calc-fontvw(28, 35);
        }
    }

    &__description {
        margin: 20px 30px 40px;
        @include calc-font(16, 24);

        @include media-sp {
            margin-top: calcP(30);
            @include calc-fontvw(16, 24);
        }
    }
}
