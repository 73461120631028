.jsPaymentMethod {
    display: none;

    &.active {
        display: block;
    }
}

.checkout-payment-method-form {
    &-wrapper {
        padding-top: 10px;

        @include media-sp {
            padding-top: calcP(10);
        }
    }

    display: flex;

    @include media-sp {
        display: block;
    }

    &-parts {
        margin: 20px 20px 0 0;

        @include media-sp {
            margin-top: calcP(20);
            margin-right: 0;
        }
    }

    &__ttl {
        margin-bottom: 10px;
        @include calc-font(14, 14);

        @include media-sp {
            margin-bottom: calcP(10);
            @include calc-fontvw(14, 14);
        }
    }

    .input__text {
        &.number,
        &.name {
            width: 350px;

            @include media-sp {
                width: 100%;
            }
        }

        &.cvv {
            width: 120px;

            @include media-sp {
                width: 100%;
            }
        }
    }

    &-select {
        display: flex;

        @include media-sp {
            justify-content: space-between;
            width: 100%;
        }

        .custom-select {
            width: 48.35820895522388%;
        }
    }

    .custom-select-form {
        &.month {
            margin-right: 10px;
            width: 90px;

            @include media-sp {
                margin-right: 0;
                width: 100%;
            }
        }

        &.year {
            width: 104px;

            @include media-sp {
                width: 100%;
            }
        }

        > select {
            width: 100%;
        }
    }
}
