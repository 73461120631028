@import "https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap";
@import "_reset";
@import "./settings";
@import "~slick-carousel/slick/slick.scss";
@import "~magnific-popup/src/css/main.scss";

body {
    min-height: 100vh;
    font-family:
        "Roboto",
        "Noto Sans JP",
        -apple-system,
        "Hiragino Sans",
        "BIZ UDPGothic",
        "Meiryo",
        "Arial",
        sans-serif;
    font-weight: 400;
    color: #666;
    @include media-sp {
        @include calc-fontvw(16, 22);
    }
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    display: flex;
    flex-direction: column;
}

body.noScroll {
    width: 100vw;
    height: 100vh;
    @supports (height: 100svh) {
        height: 100svh;
    }

    overflow: hidden;
}

@include print {
    body {
        -webkit-print-color-adjust: exact;
    }
}

a {
    color: inherit;
    text-decoration: none;

    @include media-pc {
        &:hover {
            color: #000;
            text-decoration: underline;

            img {
                opacity: 0.7;
            }
        }

        transition: 0.3s;

        img {
            transition: 0.3s;
        }
    }
}

input[type="text"] {
    border-radius: 0;
}

.pcOnly {
    @include media-sp {
        display: none !important;
    }
}

.spOnly {
    @include media-pc {
        display: none !important;
    }
}

img {
    max-width: 100%;
    height: auto;
}

.clearfix {
    @include clearfix;
}

.cmn-content-main {
    margin: 0 auto;
    width: 100%;
    max-width: 1600px;
}

.cmn-content-main-content {
    position: relative;
    padding-bottom: 150px;

    @include media-sp {
        padding-bottom: calcP(100);
    }

    &-checkout {
        margin: 0 auto;
        padding-left: 30px;
        padding-right: 30px;
        max-width: 1240px;

        @include media-sp {
            padding-left: calcP(20);
            padding-right: calcP(20);
        }
    }
}

body.header-is-fixed {
    .cmn-content-is-padding {
        padding-top: 80px;

        @include media-sp {
            padding-top: 60px;
        }
    }
}

.breadcrumbs {
    margin: 18px 0 30px;
    @include calc-font(14, 14);

    @include media-sp {
        margin: 19px 0;
        @include calc-fontvw(12, 16);
    }

    @include media-tablet {
        @include calc-fontvwtb(16, 16);
    }

    li {
        position: relative;
        float: left;
        margin-right: 14px;
        display: inline-table;

        @include media-sp {
            margin-right: 18px;
        }

        &::after {
            content: "|";
            position: absolute;
            color: #ddd;
            top: 0;
            right: -9px;
        }

        &:last-child {
            margin-right: 0;

            &::after {
                display: none;
            }
        }
    }
    &--narrow {
        margin: 10px 0 10px;
        @include calc-font(14, 14);

        @include media-sp {
            margin: 5px 0;
            @include calc-fontvw(12, 16);
        }

        @include media-tablet {
            @include calc-fontvwtb(16, 16);
        }
        
        li {
            position: relative;
            float: left;
            margin-right: 14px;

            @include media-sp {
                margin-right: 18px;
            }

            &::after {
                content: "|";
                position: absolute;
                color: #ddd;
                top: 0;
                right: -9px;
            }

            &:last-child {
                margin-right: 0;

                &::after {
                    display: none;
                }
            }
        }
    }
}

.table-basic {
    th,
    td {
        padding: 8px 15px;
        @include calc-font(14, 19);

        border: 1px solid #ddd;

        @include media-sp {
            @include calc-fontvw(14, 19);
        }
    }

    th {
        font-weight: 700;
        color: #000;
        background-color: #ebebeb;
    }

    .col-text-center {
        text-align: center;
    }

    .col-text-left {
        text-align: left;
    }

    .col-text-right {
        text-align: right;
    }

    .vertical-middle {
        vertical-align: middle;
    }
}

.time-container{
    background:#c9171e;
    padding: 5px 10px;
    margin-top: 10px;
    line-height: 0!important;
    border-radius: 6px;
}

.time-container > span{
    color:#eaedf7!important;
    font-size: 1rem!important;
}

.time-container > .time{
    display: inline-flex!important;
}

.time-container > .time > .time-container-pdp-datetime{
    display:grid!important;
    width: 40px;
    font-family: fantasy;
}

.time-container-pdp-text{
    font-size:1rem;
    transform: scale(1.2,1.5);
    width: 60px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
}

.cart__product-list-article-detail-status.product-counter{
    display: flex;
    align-items: center;

    @include media-sp {
        flex-direction: column;
        align-items: flex-start;
    }
    span.status-label, .time-container2 {
        display: flex;
    }
    .time-container2 {
        span.price-off-message, span.time{
            font-size: inherit !important;
            color: inherit !important;
            padding-top: 0 !important;
            margin-top: 5px;
        }
        @include media-sp {
            display: initial;
            .time#day-content {
                margin-left: 0 !important;
            }
            span.price-off-message {
                display: inline-block !important;
            }
        }
    }
}

.pdp-datetime-text{
    font-family: auto!important;
    font-size: 0.5rem;
}

.product-counter.block-margin {
    margin-left: 0px!important;
    margin-right: 0px!important;
}

.time-container2{
    padding: 0;
}

.time-container2 > span{
    font-size: 0.7rem!important;
    border-bottom: 2px solid red;
}

.time-container2 > .time{
    margin-left:-5px;
}

.current-price{
    margin-right: 20px;
}

.priceoff-percent{
    white-space: nowrap;
    margin-left: 0!important;
}

@import "common/_card_animation.scss";
@import "common/_card_mask_animation.scss";
@import "common/_cookie.scss";
@import "common/_custom_form.scss";
@import "common/_filter.scss";
@import "common/_footer.scss";
@import "common/_header.scss";
@import "common/_magnific_animation.scss";
@import "common/_modal.scss";
@import "common/_order_detail.scss";
@import "common/_page_up.scss";
@import "common/_pagelist.scss";
@import "common/_slick_animation.scss";
@import "common/_typo.scss";
@import "common/_underline_animation.scss";
@import "common/_information_bar.scss";
@import "common/_photoswipe.scss";

@import "page/_cart.scss";
@import "page/_checkout_buynow.scss";
@import "page/_checkout.scss";
@import "page/_home.scss";
@import "page/_myaccount_dashboard.scss";
@import "page/_myaccount_paypal.scss";
@import "page/_myaccount_points.scss";
@import "page/_myaccount_support.scss";
@import "page/_myaccount.scss";
@import "page/_myorders.scss";
@import "page/_signin.scss";
@import "page/_reset_password.scss";
@import "page/_terms_of_service.scss";
@import "page/_server_maintenance.scss";
@import "page/_not_found.scss";
@import "page/_server_error.scss";
@import "page/_faq.scss";
@import "page/_contact.scss";
@import "page/_all_series.scss";
@import "page/_product.scss";
@import "page/_news.scss";
@import "page/_information.scss";
@import "page/_contest.scss";
@import "page/_search_campaign_countdown.scss";

@import "~base/_variables.scss";
@import "components/_spinner.scss";
