.home {
    &-main-visual {
        max-width: 1920px;
        width: 100%;
        margin: 0 auto;
        z-index: 0;

        ul.slider {
            max-height: 100%;
        }

        @include media-sp {
            max-height: initial;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            object-position: center bottom;
            vertical-align: top;
        }

        a:hover,
        a:active {
            img {
                opacity: 1;
            }
        }
    }

    &-main-contents {
        position: relative;
        max-width: 1920px;
        margin: 0 auto;
        padding: 20px 0 0px;

        @include media-sp {
            max-width: initial;
            padding: calcP(15) 0;
        }

        &__wrapper {
            position: relative;
            padding: 0 30px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 auto;
            gap: 20px;
            max-width: 1920px;

            & a:hover {
                img {
                    opacity: initial;
                }
            }

            @include media-sp {
                max-width: initial;
                width: calcP(345);
                padding: 0;
                gap: 15px calcP(7);
            }
        }

        &__item {
            &--base {
                position: relative;
                flex: 1 500px;
                max-height: 720px;
                overflow: hidden;

                @media screen and (max-width: 1599px) {
                    flex-basis: 31.25%;
                }

                @include media-sp {
                    flex: auto;
                    width: 48.9855%;
                }

                img {
                    max-height: 720px;
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    vertical-align: top;
                }
            }

            &--double {
                position: relative;
                flex: 2 1020px;
                max-height: 720px;
                overflow: hidden;

                @media screen and (max-width: 1599px) {
                    flex-basis: 63.75%;
                }

                @include media-sp {
                    flex: auto;
                    width: 100%;
                }

                img {
                    max-height: 720px;
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    vertical-align: top;
                }
            }

            &--triple {
                position: relative;
                flex: 1 1540px;
                overflow: hidden;

                @media screen and (max-width: 1599px) {
                    flex-basis: 96.25%;
                }

                @include media-sp {
                    flex: auto;
                    width: 100%;
                }

                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    vertical-align: top;

                    @include media-sp {
                        height: 54.1333vw;
                    }
                }
            }

            &--triple-hd {
                position: relative;
                flex: 1 1540px;
                overflow: hidden;

                @media screen and (max-width: 1599px) {
                    flex-basis: 96.25%;
                }

                @include media-sp {
                    flex: auto;
                    width: 100%;
                }

                img {
                    max-height: 1039px;
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    vertical-align: top;
                }
            }
        }

        & a {
            display: block;

            &:hover {
                .home-card-detail {
                    opacity: 1;
                    z-index: 1;
                }
            }

            & .home-card-detail {
                @include media-pc {
                    position: absolute;
                    left: 40px;
                    bottom: 40px;
                    width: calc(100% - 80px);
                    color: #fff;
                    opacity: 0;
                }
                @include media-sp {
                    position: relative;
                    color: #666;
                    margin-top: calcP(5);
                }

                &__title {
                    font-weight: var(--font-weight-medium);
                    @include media-pc {
                        @include calc-font(24, 30);
                    }

                    @include media-sp {
                        @include calc-fontvw(14, 18);
                    }
                }

                &__price {
                    @include media-pc {
                        @include calc-font(18, 30);
                    }
                    @include media-sp {
                        font-weight: var(--font-weight-regular);
                        @include calc-fontvw(12, 18);
                    }

                    span.cancel {
                        text-decoration: line-through;
                        @include calc-font(14, 30);

                        @include media-sp {
                            @include calc-fontvw(10, 18);
                        }
                    }

                    span.discount {
                        color: #ff006c;
                    }

                    span:nth-child(n+2) {
                        padding-left: 5px;

                        @include media-sp {
                            padding-left: 1.33vw;
                        }
                    }
                }
            }
        }

        &__article {
            padding: 0 10px;
            margin-bottom: 50px;
            width: 100%;
        }
    }
    &__ttl {
        margin: 50px 0 0 0px;
        width: 100%;
        @include calc-font(24, 32);

        padding-bottom: 12px;
        color: #000;
        border-bottom: 2px solid #e8e8e8;

        @include media-sp {
            margin-top: calcP(45);
            @include calc-fontvw(22, 29);

            padding-bottom: calcP(17);
        }

        &--recommend {
            margin-top: 95px;

            @include media-sp {
                margin-top: 70px;
            }
        }
    }
}

.home-news-content {
    .home-news-list {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        margin-top: 50px;

        img {
            width: 100%;
            aspect-ratio: 5/6;
        }

        @include media-sp {
            margin-top: calcP(30);
        }

        > li {
            margin-bottom: 40px;
            width: calc((295 / 1240) * 100%);
            .tag-list {
                display: flex;
                justify-content: start;
                align-items: start;
                flex-wrap: wrap;
                margin-top: 20px;

                > li {
                    margin-bottom: 10px;
                    margin-right: 10px;
                    a {
                        background: #acacac;
                        color: #fff;
                        @include calc-font(14, 24);

                        font-weight: var(--font-weight-regular);
                        padding: 5px 7px;
                        transition: 0.3s;

                        &:hover {
                            opacity: 0.7;
                            text-decoration: none;
                        }

                        &.promo {
                            background: #748b9f;
                        }

                        &.bonus {
                            background: #8a9579;
                        }
                    }
                }
            }

            @include media-sp {
                margin-right: calc((7 / 375) * 100%);
                margin-bottom: 20px;
                width: 49%;

                &:nth-child(2n) {
                    margin-right: 0;
                }
            }

            @include media-pc {
                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
            // .home-news-list-images
            .home-news-list-ttl  {
                color: #000;
                @include calc-font(16, 21);

                font-weight: var(--font-weight-medium);
                margin-top: 10px;
                text-align: left;

                @include media-sp {
                    @include calc-fontvw(14, 18);

                    margin-top: calcP(10);
                }
            }
            .news-content-list-date {
                margin-top: 10px;
            }
        }
        &__link {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.home-information-content {
    .home-information-list {
        border-top: 1px solid #e8e8e8;
        max-width: 1400px;
        margin: 50px auto 50px;

        @include media-sp {
            margin-top: calcP(40);
        }

        &__item {
            border-bottom: 1px solid #e8e8e8;

            a {
                display: block;
                @include calc-font(16, 35);

                padding: 10px 0;

                @include media-sp {
                    @include calc-fontvw(16, 24);
                }
            }
        }
        &__link {
            display: flex;
            justify-content: flex-end;
        }
    }
    .not-found-content__text {
        margin-top: 50px;
    }
}

.product-content__detail-col {
    &.first {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        @include media-sp {
            flex-direction: column;
        }
    }

    .product-content__detail-ttl {
        border-bottom: 2px solid #e8e8e8;
        color: #000;
        @include calc-font(24, 28);

        font-weight: var(--font-weight-bold);
        padding-bottom: 10px;
        margin-bottom: 30px;

        @include media-sp {
            @include calc-fontvw(22, 25);

            margin-bottom: calcP(30);
        }
    }

    .product-content__detail-details {
        margin-top: 82px;
    }

    .product-content__detail-table-wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include media-sp {
            flex-direction: column;
        }

        table {
            width: calc((589 / 1240) * 100%);

            @include media-sp {
                width: 100%;
            }

            th,
            td {
                padding: 15px 0;
                border-bottom: 1px solid #e8e8e8;
            }

            th {
                color: #000;
                @include calc-font(16, 21);

                font-weight: var(--font-weight-medium);
                text-align: left;

                @include media-sp {
                    width: 160px;
                    @include calc-fontvw(14, 21);
                }
            }

            td {
                color: #666;
                @include calc-font(16, 21);

                font-weight: var(--font-weight-regular);

                @include media-sp {
                    @include calc-fontvw(14, 21);
                }
            }
        }
    }

    .product-content__detail-col-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 100px;

        .card-mask {
            width: calc((610 / 1240) * 100%);

            &.last {
                margin-left: calc((20 / 1240) * 100%);
            }
        }
    }

    .product-content__detail-col-card-wrap {
        margin-top: calcP(57);

        .product-content__detail-col-card {
            margin-top: 15px;

            &.first {
                margin-top: 0;
            }
        }

        .product-card-detail {
            &__title {
                @include media-sp {
                    @include calc-fontvw(14, 18);
                }
            }

            &__subtext {
                @include media-sp {
                    @include calc-fontvw(12, 18);
                }
            }
        }
    }

    .card-mask {
        &:hover {
            img {
                opacity: 1;
            }

            .product-card-detail {
                opacity: 1;
                transition: 0.3s;
            }
        }

        &__bg {
            line-height: 0.8;
        }

        .product-card-detail {
            opacity: 0;
            position: absolute;
            left: 40px;
            bottom: 40px;

            &__title {
                color: #fff;
                @include calc-font(24, 36);

                font-weight: var(--font-weight-bold);
            }

            &__subtext {
                color: #fff;
                @include calc-font(18, 36);

                font-weight: var(--font-weight-bold);
            }
        }
    }

    .product-content__detail-col-line {
        margin-top: 20px;
    }

    &__rerated {
        margin-top: 103px;

        @include media-sp {
            margin-top: calcP(70);
        }
    }

    &__reccomended {
        margin-top: 100px;

        @include media-sp {
            margin-top: calcP(70);
        }
    }

    .product-content__detail-product-list {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        column-gap: 30px;

        @include media-sp {
            flex-wrap: wrap;
        }

        li {
            width: calc((295 / 1240) * 100%);
            @include media-sp {
                width: calc((160 / 335) * 100%);
                margin-bottom: 15px;
            }

            a {
                > p {
                    color: #000;
                    margin-top: 10px;
                    text-align: center;
                    @include calc-font(16, 21);

                    font-weight: var(--font-weight-bold);

                    @include media-sp {
                        @include calc-fontvw(14, 17);
                    }
                }
            }
        }
    }
}
