.cmn-header {
    position: relative;
    width: 100%;
    z-index: 99;
    transition: top 300ms ease-out;

    .home &,
    .product & {
        position: absolute;
    }
}

body.header-is-fixed {
    .cmn-header {
        position: fixed;

        @media print {
            position: absolute;
        }
    }
}

.jsHeader {
    position: relative;
    width: 100%;
    z-index: 99;
    transition: top 300ms ease-out;
}

body.header-is-fixed {
    .jsHeader {
        position: fixed;

        @media print {
            position: absolute;
        }
    }
}

// ------ Main ------
.cmn-header__main {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(15px);
    transition: all 0.3s ease-out;

    @include media-sp {
        height: 60px;
    }

    // &:hover {
    //     background-color: rgba(255, 255, 255, 1);
    // }

    &__list {
        position: relative;
        margin-left: max(min(calc(140px + 100% - 1315px), 140px), 40px);
        // margin-left: 165px;
        // background-color: rgba(255,0,0,0.2);

        @include media-sp {
            display: none;
        }

        ul {
            position: relative;
            display: flex;
        }

        li {
            position: relative;
            @include calc-font(16, 34);

            font-weight: var(--font-weight-medium);
            color: #000;
            letter-spacing: normal;
            user-select: none;
            white-space: nowrap;

            a:hover,
            a:active {
                text-decoration: none;
            }
        }

        li + li {
            margin-left: max(min(calc(2px + calc(48 * calc(100vw - 992px) / calc(1315 - 992))), 50px), 20px);
        }

        &__selected {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 1px;
            height: 2px;
            background-color: #000;
            transition: all 0.3s ease-out;
            opacity: 0;
        }
    }

    &__menu {
        margin-left: auto;
        padding-right: 40px;
        padding-left: 40px;
        // background-color: rgba(0,255,0,0.2);

        @include media-sp {
            padding-right: 10px;
            padding-left: 20px;
        }

        & > ul {
            display: flex;

            li {
                position: relative;
                width: 36px;
                height: 36px;
                user-select: none;
            }

            li + li {
                margin-left: 20px;
            }
        }

        svg {
            vertical-align: top;
            width: 36px;
            height: 36px;
        }

        &__search,
        &__my {
            transition: opacity 0.3s ease-out;

            &:hover,
            &:active {
                opacity: 0.6;
            }

            &.active {
                opacity: 0.6;
            }
        }

        &__search {
            cursor: pointer;
        }

        &__cart {
            transition: opacity 0.3s ease-out;

            &:hover,
            &:active {
                opacity: 0.6;
            }

            &__number {
                position: absolute;
                right: 0;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 16px;
                height: 16px;
                border-radius: 8px;
                box-sizing: border-box;
                padding: 0 4px;
                @include calc-font(10, 13);

                color: #fff;
                background-color: #ff006c;
                font-weight: var(--font-weight-medium);
                pointer-events: none;
            }
        }

        &__mobile {
            @include media-pc {
                display: none;
            }

            position: relative;

            & > div {
                position: absolute;
                left: 0;
                top: 0;
                width: 18px;
                height: 2px;
                background-color: #000;
                transition: all 0.3s ease-out;
                transform-origin: center center;

                &:nth-child(1) {
                    left: 9px;
                    top: 11px;
                }

                &:nth-child(2) {
                    left: 9px;
                    top: (11px + 6);
                }

                &:nth-child(3) {
                    left: 9px;
                    top: (11px + 12);
                }
            }
        }
    }

    &__cover {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background-color: #fff;
        // background-color: rgba(255,0,0,0.2);

        @include media-pc {
            @media all and (min-width: 1481px) {
                left: 330px;
                width: calc(100% - 330px);
                padding-right: 330px;
            }
            @media all and (max-width: 1480px) {
                left: 60px;
                width: calc(100% - 60px);
                padding-right: 60px;
            }
        }

        @include media-sp {
            display: none;
        }

        &__search {
            position: relative;
            width: 428px;
            height: 44px;
            box-sizing: border-box;
            margin: 18px auto 0;
            border: solid #e8e8e8 1px;

            img {
                position: absolute;
                left: 8px;
                top: 3px;
                width: 36px;
                height: 36px;
            }

            input {
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                background-color: transparent;
                background-image: none;
                border: none;
                border-radius: 0;
                color: inherit;
                font-family: inherit;
                font-size: 1em;
                padding: 0.75em 0.5em;
                margin-left: 54px;
                width: 362px;
                box-sizing: border-box;
                color: #000;

                &:focus {
                    border: none;
                    box-shadow: none;
                    outline: none;
                }
            }
        }

        &__close {
            position: absolute;
            right: 40px;
            top: 22px;
            width: 36px;
            height: 36px;
            transition: opacity 0.3s ease-out;
            cursor: pointer;

            &:hover,
            &:active {
                opacity: 0.6;
            }

            & > div {
                position: absolute;
                left: 0;
                top: 0;
                width: 18px;
                height: 2px;
                background-color: #666;
                transition: all 0.3s ease-out;
                transform-origin: center center;

                &:nth-child(1) {
                    transform: rotate(45deg);
                    left: 9px;
                    top: (11px + 6);
                }

                &:nth-child(2) {
                    transform: rotate(-45deg);
                    left: 9px;
                    top: (11px + 6);
                }
            }
        }
    }
}

.cmn-header__logo {
    padding: 0 0 0 25px;
    box-sizing: border-box;
    @include media-pc {
        @media all and (min-width: 1481px) {
            min-width: 301px;
        }
        @media all and (max-width: 1480px) {
            min-width: (35px + 25);
        }
    }

    @include media-sp {
        position: absolute;
        left: 15px;
        top: 15px;
        padding: 0;

        img {
            vertical-align: top;
        }
    }
}

// ------ Navigation PC ------
.cmn-header__navigation-pc {
    position: absolute;
    display: none;
    width: 100%;
    height: calc(100vh - 80px);
    @supports (height: calc(100svh - 80px)) {
        top: 80px;
        height: calc(100svh - 80px);
    }

    background-color: rgba(0, 0, 0, 0.5);

    &__inner {
        position: relative;
        display: none;
        box-sizing: border-box;
        height: 0;
        // height: 410px;//本当は 356px と 410px を切り替えたい
        background-color: #fff;
        overflow: hidden;

        &.my {
            width: 230px;
            // height: initial;
            margin-left: auto;
        }
    }

    &-products,
    &-trend,
    &-collaboration,
    &-search,
    &-my {
        display: none;
    }

    &__blocks-4 {
        position: absolute;
        left: 50%;
        top: 28px;
        transform: translateX(-50%);
        display: flex;
        gap: 0 40px;
        width: calc(100% - 40px);
        max-width: 1080px;
        margin: 0 auto;
    }

    &-search &__blocks-4 {
        top: 10px;
    }

    &__blocks-3 {
        position: absolute;
        left: 50%;
        top: 28px;
        transform: translateX(-50%);
        display: flex;
        gap: 0 40px;
        width: calc(100% - 40px);
        max-width: 800px;
        margin: 0 auto;
    }

    &__block {
        position: relative;
        width: 240px;
    }

    &__block-wide {
        position: relative;
        width: 520px;
    }

    &__block-wide-devide {
        width: 520px;

        & > div {
            display: flex;
            flex-wrap: wrap;
            gap: 0 40px;

            & > div {
                width: 240px;
            }
        }

        & > div + div {
            margin-top: 25px;
        }
    }

    &__preview {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__list {
        &__head {
            @include calc-font(16, 36);

            font-weight: var(--font-weight-medium);
            color: #000;
            border-bottom: solid #e8e8e8 2px;
        }

        &-normal {
            margin-top: 20px;

            li {
                @include calc-font(16, 20);

                text-overflow: ellipsis;
                white-space: nowrap;
            }

            li + li {
                margin-top: 11px;
            }
        }

        &-narrow {
            margin-top: 18px;

            li {
                @include calc-font(14, 21);

                text-overflow: ellipsis;
                white-space: nowrap;
            }

            li + li {
                margin-top: 3px;
            }
        }

        &-my {
            padding: 20px 10px 20px 20px;

            li {
                @include calc-font(16, 20);

                text-overflow: ellipsis;
                white-space: nowrap;
            }

            li + li {
                margin-top: 10px;
            }
        }

        &-brand {
            li {
                margin-top: 20px;
            }
        }

        &-social {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-top: 20px;

            li {
                width: 47.91%;
                margin-top: 0;

                img {
                    width: 100%;
                    vertical-align: top;
                }
            }
        }

        &-thumbnail {
            height: 310px;
            margin-top: 20px;
            overflow-y: scroll;

            li {
                padding-bottom: 10px;
                @include calc-font(14, 18);
            }

            li + li {
                margin-top: 10px;
            }

            a {
                display: flex;
                align-items: center;
            }

            span:nth-child(1) {
                width: 65px;
            }

            span:nth-child(2) {
                width: calc(100% - 65px);
            }

            img {
                width: 50px;
                height: 50px;
                object-fit: cover;
                vertical-align: top;
            }
        }
    }

    &__block-wide &__list-normal {
        display: flex;
        flex-wrap: wrap;
        gap: 12px 20px;

        li {
            width: 46.15%;
            margin-top: 0;
        }
    }

    &__item-big {
        a:hover,
        a:active {
            text-decoration: none;
        }

        &__image {
            width: 100%;
            vertical-align: top;
        }

        &__head {
            margin-top: 10px;
            @include calc-font(14, 18);
        }

        &__text {
            margin-top: 2px;
            @include calc-font(12, 14);

            font-weight: var(--font-weight-regular);
            color: #666;
        }

        a:link &__head,
        a:visited &__head {
            color: #666;
        }

        a:link &__text,
        a:visited &__text {
            color: #666;
        }
    }
}

// ------ Navigation SP ------
.cmn-header__navigation-sp {
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;

    &__close {
        position: absolute;
        right: 10px;
        top: 12px;
        width: 36px;
        height: 36px;

        & > div {
            position: absolute;
            left: 0;
            top: 0;
            width: 18px;
            height: 2px;
            background-color: #000;
            transition: all 0.3s ease-out;
            transform-origin: center center;

            &:nth-child(1) {
                transform: rotate(45deg);
                left: 9px;
                top: (11px + 6);
            }

            &:nth-child(2) {
                transform: rotate(-45deg);
                left: 9px;
                top: (11px + 6);
            }
        }
    }

    &__head {
        position: relative;

        &__logo {
            display: none;
            position: absolute;
            left: 15px;
            top: 15px;

            img {
                vertical-align: top;
            }
        }

        &__home {
            display: none;
            position: relative;
            margin: 0 20px;
            padding: 20px 16px;
            @include calc-font(16, 21);

            font-weight: var(--font-weight-medium);
            color: #000;
            opacity: 1;
            transition: all 0.3s ease-out;
            user-select: none;
            cursor: pointer;

            img {
                position: absolute;
                left: 0;
                top: calc(50% - 6px);
                vertical-align: top;
                transform: rotate(180deg);
            }
        }
    }

    &__nav-head {
        margin: 0 20px;
        color: #000;
        font-weight: var(--font-weight-medium);
    }

    &__nav-list {
        margin: 0 20px;

        li {
            user-select: none;

            a,
            span {
                position: relative;
                display: block;
                width: 100%;
                padding: 12px 10px 16px;
                text-align: left;
                box-sizing: border-box;
                background-color: #fff;
                @extend %img-filter;

                border-top: 1px solid #e8e8e8;
                cursor: pointer;

                @include media-sp {
                    padding: calcP(14) calcP(10) calcP(16);
                    @include calc-fontvw(16, 16);
                }

                img {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    margin-top: -5px;
                }

                @include media-pc {
                    &:hover {
                        text-decoration: none;
                        background-color: #f1f1f1;
                    }
                }

                &.active {
                    color: #000;
                    background-color: #f1f1f1;

                    img {
                        filter: none;
                    }
                }
            }

            &:last-child {
                a,
                span {
                    border-bottom: 1px solid #e8e8e8;
                }
            }
        }
    }

    &__nav-head + &__nav-list {
        margin-top: calcP(16);
    }

    &__nav-list + &__nav-head {
        margin-top: calcP(32);
    }

    &__nav-list-brand {
        margin: 0 20px;

        li {
            margin-top: calcP(16);

            img {
                width: calcP(240);
            }

            a {
                &:link,
                &:visited {
                    opacity: 1;
                    transition: all 0.3s ease-out;
                }

                &:hover,
                &:active {
                    opacity: 0.7;
                }
            }
        }
    }

    &__nav-list-social {
        display: flex;
        flex-wrap: wrap;
        gap: 11px;
        margin: 12px 20px;

        li {
            width: 162px;

            a {
                &:link,
                &:visited {
                    opacity: 1;
                    transition: all 0.3s ease-out;
                }

                &:hover,
                &:active {
                    opacity: 0.7;
                }
            }
        }
    }

    //
    &-base,
    &-products,
    &-trend,
    &-collaboration,
    &-my,
    &-search {
        display: none;
        position: absolute;
        left: 0;
        top: 78px;
        width: 100%;
        padding-bottom: 140px;
    }

    &-base &__nav-list {
        li {
            a,
            span {
                color: #000;
                font-weight: var(--font-weight-medium);
            }
        }
    }

    &-base{
        z-index: 200;
    }

    &-trend ,
    &-collaboration ,
    &-products {
        z-index: 100;
    }

    &-search {
        &__input {
            position: relative;
            margin: 0 20px -8px;
            border: solid #ddd 1px;

            img {
                position: absolute;
                left: 4px;
                top: 4px;
                width: 36px;
                height: 36px;
            }

            input {
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                background-color: transparent;
                background-image: none;
                border: none;
                border-radius: 0;
                color: inherit;
                font-family: inherit;
                font-size: 1em;
                padding: 0.75em 0.5em;
                margin-left: 44px;
                width: calc(100vw - 42px - 44px);
                box-sizing: border-box;
                color: #000;

                &:focus {
                    border: none;
                    box-shadow: none;
                    outline: none;
                }
            }
        }

        &__list {
            &-simple {
                margin: 16px 20px 0;

                li {
                    color: #666;
                }

                li + li {
                    margin-top: 20px;
                }
            }

            &-thumbnail {
                margin: 20px 20px 0;

                li {
                    border-bottom: solid #ddd 1px;
                    padding-bottom: 10px;
                }

                li + li {
                    margin-top: 10px;
                }

                a {
                    display: flex;
                    align-items: center;
                }

                span:nth-child(1) {
                    width: 65px;
                }

                span:nth-child(2) {
                    width: calc(100% - 65px);
                }

                img {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                    vertical-align: top;
                }
            }
        }
    }

    &-search &__nav-head {
        margin-top: 34px;
        border-bottom: solid #ddd 2px;
        padding-bottom: 6px;
    }
}
