.cmn-page-up {
    position: fixed;
    bottom: 25px;
    right: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    opacity: 0;
    z-index: 8;
    transition: opacity 0.3s ease, background-color 0.3s ease;

    @include media-sp {
        bottom: 25px;
        right: 25px;
        width: 9.6vw;
        height: 9.6vw;
    }

    &::after {
        content: '';
        position: absolute;
        width: 11.414px;
        height: 7.121px;
        background: url(../images/common/icon_arrow_up.svg) no-repeat center;
        background-size: contain;

        @include media-sp {
            width: 2.1333vw;
            height: 1.3306vw;
        }
    }

    &:hover {
        background-color: #fff;
    }

    &.jsAppear {
        animation: appearAnime 0.3s forwards;
    }

    @keyframes appearAnime {
        from {
            opacity: 0;
            transform: translateY(50px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &.jsDisappear {
        animation: disappearAnime 0.3s forwards;
    }

    @keyframes disappearAnime {
        from {
            opacity: 1;
            transform: translateY(0);
        }

        to {
            opacity: 0;
            transform: translateY(50px);
        }
    }
}
