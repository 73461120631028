.card-mask {
    position: relative;

    &__bg {
        position: relative;
        overflow: hidden;

        @include media-pc {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);
                opacity: 0;
                transform: translateY(100%);
                transition: transform 0.3s ease;
            }
        }
    }

    &:hover .card-mask__bg {

        @include media-pc {
            &::before {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}
